import React from "react";
import { FaExclamationTriangle, FaRegSadTear } from "react-icons/fa";

const CancelSubscriptionModal = ({
  isOpen,
  onClose,
  onConfirm,
  isCanceled,
  currentPlan,
}) => {
  if (!isOpen) return null;

  if (isCanceled) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative mx-auto max-w-md rounded-lg bg-white p-6 shadow-xl">
          <div className="flex flex-col items-center text-center">
            <FaRegSadTear className="mb-4 text-5xl text-gray-400" />
            <h3 className="mb-2 text-xl font-semibold text-gray-900">
              Que pena que você está indo embora...
            </h3>
            <p className="text-sm text-gray-600">
              Sua assinatura foi cancelada com sucesso. Você ainda terá acesso
              aos recursos do plano {currentPlan} até o final do período atual.
            </p>
            <p className="mt-4 text-sm text-gray-500">
              Esperamos ver você novamente em breve!
            </p>
            <button
              onClick={onClose}
              className="mt-6 rounded-lg bg-gray-500 px-4 py-2 text-sm font-medium text-white hover:bg-gray-600"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative mx-auto max-w-md rounded-lg bg-white p-6 shadow-xl">
        <div className="flex items-center text-xl font-semibold text-gray-900">
          <FaExclamationTriangle className="mr-2 text-red-500" />
          Tem certeza que deseja cancelar?
        </div>

        <div className="mt-4 space-y-4">
          <p className="text-sm text-gray-600">
            Ao cancelar sua assinatura, você perderá acesso a recursos
            importantes:
          </p>

          <div className="rounded-lg bg-red-50 p-4">
            <ul className="list-inside list-disc space-y-2 text-sm text-gray-600">
              <li>Otimização automática de campanhas</li>
              <li>Sistema avançado de ROI</li>
              <li>Suporte prioritário</li>
              <li>Recursos exclusivos do plano {currentPlan}</li>
              <li>Preços especiais em créditos</li>
            </ul>
          </div>

          <p className="text-sm font-medium text-gray-700">
            Sua assinatura atual continuará ativa até o final do período já
            pago.
          </p>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="rounded-lg px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
          >
            Manter assinatura
          </button>
          <button
            onClick={onConfirm}
            className="rounded-lg bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-600"
          >
            Confirmar cancelamento
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
