import React, { useState } from "react";
import { cancelSubscription } from "../utils/api";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import Spinner from "./Spinner";

const CancelSubscriptionButton = ({ subscriptionId, currentPlan }) => {
  const [showModal, setShowModal] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelSubscription = async () => {
    if (!subscriptionId) {
      alert("ID da assinatura não encontrado");
      return;
    }

    setIsLoading(true);
    try {
      const response = await cancelSubscription(subscriptionId);
      if (response.success) {
        setIsCanceled(true);
      } else {
        alert(
          response.error || "Erro ao cancelar assinatura. Tente novamente."
        );
      }
    } catch (error) {
      console.error("Erro:", error);
      alert("Erro ao processar a solicitação. Tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        disabled={isLoading}
        className="mt-4 text-sm text-red-500 hover:text-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <Spinner className="mr-2" />
            Cancelando...
          </span>
        ) : (
          "Cancelar assinatura"
        )}
      </button>

      <CancelSubscriptionModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          if (isCanceled) {
            window.location.reload();
          }
        }}
        onConfirm={handleCancelSubscription}
        isCanceled={isCanceled}
        currentPlan={currentPlan}
        isLoading={isLoading}
      />
    </>
  );
};

export default CancelSubscriptionButton;
