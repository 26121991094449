import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  listAllProjects,
  createProject,
  deleteProject,
  getUserInfo,
  getAdGroupsUsage,
} from "../utils/api";
import { jwtDecode } from "jwt-decode";
import {
  FaTrash,
  FaSpinner,
  FaPlus,
  FaProjectDiagram,
  FaUsers,
  FaCalendarAlt,
  FaSearch,
  FaChartLine,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import CountdownPromo from "../components/CountdownPromo";

const StatsCard = ({ title, value, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow-sm p-4">
    <div className="flex items-center gap-2 text-gray-500 mb-2">
      <Icon className="text-gray-400" size={16} />
      <p className="text-sm">{title}</p>
    </div>
    <p className="text-xl font-medium">{value}</p>
  </div>
);

const ProjectCard = ({ project, onDelete, isLoading, userId }) => {
  const navigate = useNavigate();

  const getProjectRole = (project) => {
    if (project.accountId === userId) {
      return {
        text: "Proprietário",
        className: "bg-green-100 text-green-800",
      };
    } else if (
      project.trafficManagersIds &&
      Array.isArray(project.trafficManagersIds) &&
      project.trafficManagersIds.includes(userId)
    ) {
      return {
        text: "Gestor de Tráfego",
        className: "bg-blue-100 text-blue-800",
      };
    }
    return {
      text: "Visualizador",
      className: "bg-gray-100 text-gray-800",
    };
  };

  const getProjectStatus = (project) => {
    if (!project.lastErrorMessages?.length) {
      return {
        text: "Rodando normalmente",
        className: "text-green-600",
        icon: "✓",
      };
    }

    const lastError =
      project.lastErrorMessages[project.lastErrorMessages.length - 1];
    let errorMessage;
    let parsedError = null;
    let errorDate = new Date();

    try {
      parsedError = JSON.parse(lastError);
      errorMessage = parsedError.message;
      errorDate = new Date(parsedError.date);
    } catch {
      // Se não conseguir fazer o parse, usa a mensagem original
      errorMessage = lastError;
      // Mantém a data atual como fallback
    }

    // Verifica se o erro é recente (últimos 40 minutos)
    const isRecent = new Date() - errorDate < 40 * 60 * 1000;

    if (!isRecent) {
      return {
        text: "Rodando normalmente",
        className: "text-green-600",
        icon: "✓",
      };
    }

    // Se o erro for uma string JSON com [PLAN_ERROR], extrai apenas a mensagem relevante
    if (errorMessage.includes("[PLAN_ERROR]")) {
      errorMessage = errorMessage.split("|")[1]?.trim() || errorMessage;
    }

    return {
      text: errorMessage,
      className: "text-red-600",
      icon: "⚠️",
    };
  };

  const role = getProjectRole(project);
  const status = getProjectStatus(project);

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow">
      <div className="p-4">
        {/* Cabeçalho */}
        <div className="flex justify-between items-start mb-3">
          <div className="min-w-0 flex-1">
            <h3 className="text-lg font-medium text-gray-900 truncate">
              {project.projectName || project.projectType}
            </h3>
            <p className="text-sm text-gray-500 truncate">
              {project.projectAbbreviation}
            </p>
          </div>
          {project.accountId === userId && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(project.id);
              }}
              disabled={isLoading}
              className="text-red-500 hover:text-red-700 p-1"
            >
              {isLoading ? <FaSpinner className="animate-spin" /> : <FaTrash />}
            </button>
          )}
        </div>

        {/* Informações Principais */}
        <div className="space-y-2 mb-4">
          <div className="flex items-center text-sm text-gray-500">
            <span className="truncate">
              {project.adGroupsCount || 0} grupos de anúncios
            </span>
          </div>
          <div className="flex items-center gap-1 text-sm">
            <span className={status.className}>
              {status.icon} {status.text}
            </span>
          </div>
        </div>

        {/* Footer com Ações */}
        <div className="flex flex-wrap items-center justify-between mt-4">
          <span
            className={`px-2 py-1 rounded-full text-xs font-medium ${role.className}`}
          >
            {role.text}
          </span>
          <div className="flex flex-wrap gap-2 mt-2 sm:mt-0">
            <button
              onClick={() => navigate(`/project/${project.id}/performance`)}
              className="text-gray-600 hover:text-gray-800 text-sm font-medium flex items-center"
            >
              <FaChartLine className="mr-1" /> Performance
            </button>
            <button
              onClick={() => navigate(`/project/${project.id}`)}
              className="text-blue-600 hover:text-blue-800 text-sm font-medium flex items-center"
            >
              Configurar →
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Modificar o componente PlanDashboard para receber projects como prop
const PlanDashboard = ({ plan, usage, projects, userId }) => {
  if (!plan?.plan) return null;

  const isFree = plan.plan.name.toLowerCase() === "free";
  const endDate = isFree
    ? "Vitalício"
    : new Date(plan.plan.endDate).toLocaleDateString();

  // Filtra apenas os projetos onde o usuário é proprietário
  const ownedProjects =
    projects?.filter((project) => project.accountId === userId) || [];

  const planFeatures = [
    {
      title: "Projetos",
      value: `${ownedProjects.length || 0} / ${
        plan.plan.projects_available || 0
      }`,
      percentage: Math.round(
        ((ownedProjects.length || 0) / (plan.plan.projects_available || 1)) *
          100
      ),
    },
    {
      title: "Grupos de Anúncios",
      value: `${usage?.totalAdGroups?.totalAdGroups || 0} / ${
        plan.plan.googleads?.gads_groups_available || 0
      }`,
      percentage: Math.round(
        ((usage?.totalAdGroups?.totalAdGroups || 0) /
          (plan.plan.googleads?.gads_groups_available || 1)) *
          100
      ),
    },
  ];

  const automationFeatures = [
    { name: "Bot CPC", enabled: plan.plan.automations?.bot_cpc || false },
    {
      name: "Otimização Search",
      enabled: false,
      inDevelopment: true,
    },
    {
      name: "Otimização Display",
      enabled: false,
      inDevelopment: true,
    },
  ];

  const integrationFeatures = [
    {
      name: "Google Ads",
      enabled: plan.plan.googleads?.gads_integration || false,
    },
    {
      name: "Meta Ads",
      enabled: false,
      inDevelopment: true,
    },
    {
      name: "Pinterest Ads",
      enabled: false,
      inDevelopment: true,
    },
    {
      name: "Bing Ads",
      enabled: false,
      inDevelopment: true,
    },
  ];

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
        {/* Cabeçalho do Plano */}
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-bold capitalize">{plan.plan.name}</h2>
            <p className="text-sm text-gray-500">{plan.plan.description}</p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-500">Validade</p>
            <p className="text-lg font-semibold">{endDate}</p>
          </div>
        </div>

        {/* Métricas Principais */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          {planFeatures.map((feature, index) => (
            <div key={index} className="p-4 border rounded-lg">
              <p className="text-sm text-gray-500">{feature.title}</p>
              <p className="text-xl font-bold">{feature.value}</p>
              {feature.percentage !== undefined && (
                <div className="mt-2">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className={`h-2 rounded-full ${
                        feature.percentage > 80
                          ? "bg-red-500"
                          : feature.percentage > 60
                          ? "bg-yellow-500"
                          : "bg-green-500"
                      }`}
                      style={{ width: `${feature.percentage}%` }}
                    />
                  </div>
                  <p className="text-xs text-gray-500 mt-1">
                    {feature.percentage}% utilizado
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Recursos do Plano */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Automações */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-3">
              Automações
            </h3>
            <div className="space-y-2">
              {automationFeatures.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <span
                    className={`w-2 h-2 rounded-full ${
                      feature.inDevelopment
                        ? "bg-yellow-500"
                        : feature.enabled
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } mr-2`}
                  />
                  <span
                    className={`text-sm ${
                      feature.inDevelopment
                        ? "text-yellow-700"
                        : feature.enabled
                        ? "text-gray-700"
                        : "text-gray-400"
                    }`}
                  >
                    {feature.name}
                    {feature.inDevelopment && (
                      <span className="ml-2 text-xs text-yellow-600">
                        (Em desenvolvimento)
                      </span>
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Integrações */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-3">
              Integrações
            </h3>
            <div className="space-y-2">
              {integrationFeatures.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <span
                    className={`w-2 h-2 rounded-full ${
                      feature.inDevelopment
                        ? "bg-yellow-500"
                        : feature.enabled
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } mr-2`}
                  />
                  <span
                    className={`text-sm ${
                      feature.inDevelopment
                        ? "text-yellow-700"
                        : feature.enabled
                        ? "text-gray-700"
                        : "text-gray-400"
                    }`}
                  >
                    {feature.name}
                    {feature.inDevelopment && (
                      <span className="ml-2 text-xs text-yellow-600">
                        (Em desenvolvimento)
                      </span>
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Usando o componente separado */}
      {isFree && <CountdownPromo />}
    </>
  );
};

const Projects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectAbbreviation, setProjectAbbreviation] = useState("");
  const [projectType, setProjectType] = useState("googleads/admanager");
  const [userId, setUserId] = useState(null);
  const [canCreateProject, setCanCreateProject] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [nameError, setNameError] = useState("");
  const [abbreviationError, setAbbreviationError] = useState("");
  const [stats, setStats] = useState({
    totalProjects: 0,
    ownedProjects: 0,
    managedProjects: 0,
  });
  const [userPlan, setUserPlan] = useState(null);
  const [adGroupsUsage, setAdGroupsUsage] = useState(null);

  useEffect(() => {
    const checkPermissions = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.scopes && decodedToken.scopes.includes("projects")) {
          setHasPermission(true);
          setUserId(decodedToken.id);
          setCanCreateProject(decodedToken.scopes.includes("gads-integration"));

          // Busca informações do usuário
          try {
            const userInfo = await getUserInfo();
            if (userInfo.success) {
              localStorage.setItem("userPlan", JSON.stringify(userInfo.data));
              setUserPlan(userInfo.data);
            }

            const usage = await getAdGroupsUsage(decodedToken.id);
            setAdGroupsUsage(usage);
          } catch (error) {
            console.error("Erro ao buscar informações do usuário:", error);
          }

          fetchProjects();
        } else {
          navigate("/denied");
        }
      } else {
        navigate("/login");
      }
    };

    checkPermissions();
  }, [navigate]);

  const fetchProjects = async () => {
    setLoadingProjects(true);
    try {
      const projectsData = await listAllProjects();
      if (!projectsData || !Array.isArray(projectsData)) {
        throw new Error("Dados de projetos inválidos");
      }

      // Ordena os projetos do mais recente para o mais antigo
      const sortedProjects = projectsData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );

      setProjects(sortedProjects);

      // Calcula estatísticas corretamente
      const ownedProjects = projectsData.filter(
        (project) => project.accountId === userId
      );

      const managedProjects = projectsData.filter(
        (project) =>
          project.accountId !== userId && // Não é proprietário
          project.trafficManagersIds && // Existe array de gerentes
          Array.isArray(project.trafficManagersIds) && // É realmente um array
          project.trafficManagersIds.includes(userId) // Usuário está no array
      );

      setStats({
        totalProjects: projectsData.length,
        ownedProjects: ownedProjects.length,
        managedProjects: managedProjects.length,
      });
    } catch (error) {
      toast.error("Erro ao listar os projetos.");
      console.error("Erro ao listar projetos:", error);
    } finally {
      setLoadingProjects(false);
    }
  };

  const handleCreateProject = async () => {
    if (nameError || abbreviationError) {
      toast.error("Por favor, corrija os erros antes de prosseguir.");
      return;
    }

    setLoadingCreate(true);

    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const accountId = decodedToken.id;

      const newProjectData = {
        projectName,
        projectAbbreviation,
        projectType,
        accountId: accountId,
      };

      const response = await createProject(newProjectData);

      if (response && response.id) {
        setIsModalOpen(false); // Fecha o modal após a criação do projeto
        setProjectName("");
        setProjectAbbreviation("");
        setNameError("");
        setAbbreviationError("");
        fetchProjects(); // Recarrega a lista de projetos após a criação
        toast.success("Projeto criado com sucesso!");
      } else {
        // Se a resposta da API não for sucesso, lança um erro
        throw new Error(response.error || "Erro ao criar o projeto");
      }
    } catch (error) {
      toast.error(`Erro ao criar o projeto: ${error.message}`);
      console.error("Erro ao criar projeto:", error);
    } finally {
      setLoadingCreate(false);
    }
  };

  const handleProjectClick = (projectId) => {
    window.location.href = `/project/${projectId}`; // Redireciona para o dashboard dinâmico do projeto
  };

  const renderProjectRole = (project) => {
    if (project.accountId === userId) {
      return <span className="text-green-600 font-semibold">Proprietário</span>;
    } else if (project.trafficManagersIds?.includes(userId)) {
      return <span className="text-blue-600 font-semibold">Gerente</span>;
    }
    return <span className="text-gray-600">Visualizador</span>;
  };

  // Função para validar o nome do projeto
  const validateProjectName = (name) => {
    // Transforma a primeira letra em maiúscula
    const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
    setProjectName(formattedName);

    // Validação: máximo de 20 caracteres ou 4 palavras
    const wordCount = formattedName.trim().split(/\s+/).length;
    if (formattedName.length > 20 || wordCount > 4) {
      setNameError("O nome deve ter no máximo 20 caracteres ou 4 palavras.");
    } else {
      setNameError("");
    }
  };

  // Função para validar a sigla do projeto
  const validateProjectAbbreviation = (abbreviation) => {
    // Remove espaços e caracteres especiais, transforma em maiúsculas
    const formattedAbbreviation = abbreviation
      .replace(/[^A-Za-z0-9]/g, "")
      .toUpperCase();
    setProjectAbbreviation(formattedAbbreviation);

    // Validação: entre 3 e 7 caracteres
    if (formattedAbbreviation.length < 3 || formattedAbbreviation.length > 7) {
      setAbbreviationError("A sigla deve ter entre 3 e 7 caracteres.");
    } else {
      setAbbreviationError("");
    }
  };

  const handleDeleteProject = async (projectId) => {
    setLoadingDelete((prev) => ({ ...prev, [projectId]: true }));
    try {
      const response = await deleteProject(projectId);

      if (response.success) {
        // Verifique se o success foi retornado
        toast.success("Projeto deletado com sucesso!");
        fetchProjects(); // Recarrega a lista de projetos após a deleção
      } else {
        // Caso a API não retorne sucesso, exibe erro
        throw new Error(response.error || "Erro ao deletar projeto");
      }
    } catch (error) {
      toast.error(`Erro ao deletar o projeto`);
      console.error("Erro ao deletar projeto:", JSON.stringify(error));
    } finally {
      setLoadingDelete((prev) => ({ ...prev, [projectId]: false }));
    }
  };

  if (!hasPermission) return null;

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-medium">Projetos</h1>
        {canCreateProject && (
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm hover:bg-blue-700 transition-colors flex items-center gap-2"
          >
            <FaPlus size={14} />
            Novo Projeto
          </button>
        )}
      </div>

      {/* PlanDashboard agora inclui o CountdownBanner quando necessário */}
      <PlanDashboard
        plan={userPlan}
        usage={adGroupsUsage}
        projects={projects}
        userId={userId}
      />

      {/* Search */}
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <div className="relative">
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar projetos..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      {/* Projects List */}
      {loadingProjects ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : projects.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              onDelete={handleDeleteProject}
              isLoading={loadingDelete[project.id]}
              userId={userId}
            />
          ))}
        </div>
      ) : (
        <div className="text-center bg-white rounded-lg shadow-sm p-8">
          <FaProjectDiagram className="mx-auto text-gray-400 text-4xl mb-4" />
          <p className="text-gray-600 mb-4">
            {searchTerm
              ? "Nenhum projeto encontrado com os termos da busca."
              : "Você ainda não possui projetos. Crie seu primeiro projeto!"}
          </p>
          {canCreateProject && !searchTerm && (
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-blue-600 text-white px-6 py-2 rounded-lg text-sm hover:bg-blue-700 transition-colors inline-flex items-center gap-2"
            >
              <FaPlus size={14} />
              Criar Primeiro Projeto
            </button>
          )}
        </div>
      )}

      {/* Modal de Criação de Projeto */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
            <div className="px-6 py-4 border-b border-gray-200">
              <h2 className="text-xl font-semibold text-gray-800">
                Novo Projeto
              </h2>
            </div>

            <div className="p-6">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nome do Projeto
                  </label>
                  <input
                    type="text"
                    value={projectName}
                    onChange={(e) => validateProjectName(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Nome do Projeto"
                  />
                  {nameError && (
                    <p className="mt-1 text-xs text-red-500">{nameError}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Sigla do Projeto
                  </label>
                  <input
                    type="text"
                    value={projectAbbreviation}
                    onChange={(e) =>
                      validateProjectAbbreviation(e.target.value)
                    }
                    className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Sigla do Projeto"
                  />
                  {abbreviationError && (
                    <p className="mt-1 text-xs text-red-500">
                      {abbreviationError}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tipo do Projeto
                  </label>
                  <select
                    value={projectType}
                    onChange={(e) => setProjectType(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                    disabled
                  >
                    <option value="googleads/admanager">
                      Arbitragem - AdManager/Google Ads
                    </option>
                  </select>
                </div>

                <p className="text-xs text-gray-500 mt-4">
                  Atenção: Todos os projetos que utilizam AdManager devem
                  obrigatoriamente incluir parâmetros UTM. Esses parâmetros
                  devem estar presentes no link final dos anúncios na plataforma
                  selecionada.
                </p>
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  onClick={() => {
                    setIsModalOpen(false);
                    setProjectName("");
                    setProjectAbbreviation("");
                    setNameError("");
                    setAbbreviationError("");
                  }}
                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleCreateProject}
                  disabled={loadingCreate || nameError || abbreviationError}
                  className="px-4 py-2 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:bg-blue-300 flex items-center gap-2"
                >
                  {loadingCreate && <FaSpinner className="animate-spin" />}
                  Criar Projeto
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Toaster position="top-right" />
    </div>
  );
};

export default Projects;
