import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getProjects } from "../utils/api";
import {
  FaProjectDiagram,
  FaSearch,
  FaExternalLinkAlt,
  FaExclamationTriangle,
} from "react-icons/fa";
import { toast, Toaster } from "react-hot-toast";

const ProjectCard = ({ project, navigate }) => {
  if (!project) return null;

  const handleProjectClick = (e) => {
    e.preventDefault();
    if (project?.id) {
      navigate(`/project-admin/${project.id}`);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
      <div className="flex items-start justify-between">
        <div>
          <h3 className="text-lg font-medium text-gray-900">
            {project.projectName || "Sem nome"}
          </h3>
          <p className="text-sm text-gray-500">
            {project.projectAbbreviation || "N/A"}
          </p>
        </div>
        <div className="bg-blue-100 p-2 rounded-lg">
          <FaProjectDiagram className="text-blue-600" />
        </div>
      </div>

      <div className="mt-4 space-y-2">
        <div className="text-sm">
          <span className="text-gray-500">Tipo: </span>
          <span className="text-gray-700">{project.projectType}</span>
        </div>
        {project.domain && (
          <div className="text-sm">
            <span className="text-gray-500">Domínio: </span>
            <span className="text-gray-700">{project.domain}</span>
          </div>
        )}
        <div className="text-sm">
          <span className="text-gray-500">Plano: </span>
          <span className="text-gray-700 capitalize">
            {project.planInfo?.name || "N/A"}
          </span>
        </div>
        <div className="text-sm">
          <span className="text-gray-500">Grupos de Anúncios: </span>
          <span className="text-gray-700 font-medium">
            {project.adGroupsCount || 0}
          </span>
        </div>
        {project.config && (
          <div className="text-sm">
            <span className="text-gray-500">ROI Meta: </span>
            <span className="text-gray-700">
              {project.config.roiGoal || 0}%
            </span>
          </div>
        )}
      </div>

      <div className="mt-4 pt-4 border-t">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">
              Criado em: {new Date(project.createdAt).toLocaleDateString()}
            </span>
            <button
              onClick={handleProjectClick}
              className="text-blue-600 hover:text-blue-800"
            >
              Detalhes
            </button>
          </div>
          {project.config && (
            <div className="flex gap-2 text-xs">
              <span
                className={`px-2 py-1 rounded-full ${
                  project.config.runReports
                    ? "bg-green-100 text-green-800"
                    : "bg-gray-100 text-gray-800"
                }`}
              >
                {project.config.runReports
                  ? "Relatórios Ativos"
                  : "Relatórios Inativos"}
              </span>
              <span
                className={`px-2 py-1 rounded-full ${
                  project.config.cpcBotEnable
                    ? "bg-green-100 text-green-800"
                    : "bg-gray-100 text-gray-800"
                }`}
              >
                {project.config.cpcBotEnable
                  ? "Bot CPC Ativo"
                  : "Bot CPC Inativo"}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ErrorMessage = ({ code, message }) => (
  <div className="flex flex-col items-center justify-center h-64">
    <div className="bg-red-50 border border-red-200 rounded-lg p-8 max-w-lg text-center">
      <FaExclamationTriangle className="text-red-500 text-4xl mx-auto mb-4" />
      <h2 className="text-xl font-semibold text-gray-800 mb-2">Erro {code}</h2>
      <p className="text-gray-600">{message}</p>
      <button
        onClick={() => window.history.back()}
        className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
      >
        Voltar
      </button>
    </div>
  </div>
);

const UserProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { accountId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, [accountId]);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getProjects(accountId ? Number(accountId) : null);

      if (response.success) {
        setProjects(response.data);
      } else {
        setError({
          code: response.error?.status || 400,
          message: response.error?.message || "Erro ao carregar projetos",
        });
      }
    } catch (error) {
      setError({
        code: error.response?.status || 500,
        message: error.response?.data?.message || "Erro interno do servidor",
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredProjects = projects.filter(
    (project) =>
      project &&
      project.projectName &&
      project.projectAbbreviation &&
      (project.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.projectAbbreviation
          .toLowerCase()
          .includes(searchTerm.toLowerCase()))
  );

  const totalGroupsUsed = projects.reduce(
    (sum, project) => sum + (project?.adGroupsCount || 0),
    0
  );

  const maxGroupsAvailable =
    projects[0]?.planInfo?.googleads?.gads_groups_available || 0;

  const totalUsagePercentage = maxGroupsAvailable
    ? (totalGroupsUsed / maxGroupsAvailable) * 100
    : 0;

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return <ErrorMessage code={error.code} message={error.message} />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      <div className="flex justify-between items-start mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">
            {accountId ? "Projetos do Usuário" : "Todos os Projetos"}
          </h1>
          <div className="mt-2 space-y-2">
            <p className="text-sm text-gray-500">
              Total: {filteredProjects?.length || 0} projetos
            </p>
            {maxGroupsAvailable > 0 && (
              <div className="max-w-md">
                <div className="flex items-center justify-between text-sm mb-1">
                  <span className="text-gray-600">
                    Uso de Grupos de Anúncios
                  </span>
                  <span className="font-medium">
                    {totalGroupsUsed} / {maxGroupsAvailable}
                  </span>
                </div>
                <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className={`h-full rounded-full transition-all ${
                      totalUsagePercentage >= 90
                        ? "bg-red-500"
                        : totalUsagePercentage >= 70
                        ? "bg-yellow-500"
                        : "bg-green-500"
                    }`}
                    style={{ width: `${Math.min(totalUsagePercentage, 100)}%` }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {accountId && (
          <button
            onClick={() => navigate("/user-projects")}
            className="text-blue-600 hover:text-blue-800 flex items-center gap-2"
          >
            <span>Ver Todos</span>
            <FaExternalLinkAlt size={14} />
          </button>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <div className="relative">
          <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar por nome ou abreviação..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredProjects.map((project) => (
          <ProjectCard key={project.id} project={project} navigate={navigate} />
        ))}
      </div>

      <Toaster position="top-right" />
    </div>
  );
};

export default UserProjects;
