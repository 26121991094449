import React, { useState, useEffect } from "react";
import { FaUser, FaLock } from "react-icons/fa";
import {
  getUserInfo,
  updateUserProfile,
  changePassword,
  deleteAccount,
} from "../utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({
    id: null,
    name: "",
    email: "",
  });
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    loadUserProfile();
  }, []);

  const loadUserProfile = async () => {
    try {
      const response = await getUserInfo();
      if (response.success) {
        setProfile({
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
        });
      } else {
        throw new Error(response.error || "Erro ao carregar perfil");
      }
    } catch (error) {
      toast.error("Erro ao carregar perfil: " + error.message);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatePromise = updateUserProfile(profile);

    toast.promise(updatePromise, {
      loading: "Atualizando perfil...",
      success: "Perfil atualizado com sucesso!",
      error: (err) => `Erro ao atualizar perfil: ${err.message}`,
    });

    try {
      await updatePromise;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (
      !passwords.currentPassword ||
      !passwords.newPassword ||
      !passwords.confirmPassword
    ) {
      toast.error("Todos os campos são obrigatórios");
      return;
    }

    if (passwords.newPassword !== passwords.confirmPassword) {
      toast.error("A confirmação de senha não corresponde à nova senha");
      return;
    }

    if (passwords.newPassword.length < 6) {
      toast.error("A nova senha deve ter pelo menos 6 caracteres");
      return;
    }

    if (passwords.currentPassword.length < 6) {
      toast.error("A senha atual deve ter pelo menos 6 caracteres");
      return;
    }

    setLoading(true);
    const toastId = toast.loading("Alterando senha...");

    try {
      const response = await changePassword({
        id: Number(profile.id),
        oldPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
      });

      if (response.success) {
        toast.dismiss(toastId);
        toast.success("Senha alterada com sucesso!");
        setPasswords({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(error.message || "Erro ao alterar senha");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        "Tem certeza que deseja deletar sua conta? Esta ação não pode ser desfeita."
      )
    ) {
      const deletePromise = deleteAccount();

      toast.promise(deletePromise, {
        loading: "Deletando conta...",
        success: () => {
          navigate("/login");
          return "Conta deletada com sucesso";
        },
        error: (err) => `Erro ao deletar conta: ${err.message}`,
      });

      try {
        await deletePromise;
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Configurações</h1>

      <div className="bg-white rounded-lg shadow p-6 space-y-6">
        {/* Seção de Perfil */}
        <form onSubmit={handleProfileUpdate}>
          <div>
            <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <FaUser className="mr-2" />
              Informações do Perfil
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nome
                </label>
                <input
                  type="text"
                  value={profile.name}
                  onChange={(e) =>
                    setProfile({ ...profile, name: e.target.value })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  E-mail
                </label>
                <input
                  type="email"
                  value={profile.email}
                  readOnly
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                />
              </div>
            </div>
            <div className="mt-4">
              <button
                type="submit"
                disabled={loading}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50"
              >
                {loading ? "Salvando..." : "Salvar Alterações"}
              </button>
            </div>
          </div>
        </form>

        {/* Seção de Segurança */}
        <form onSubmit={handlePasswordChange} className="pt-6 border-t">
          <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
            <FaLock className="mr-2" />
            Alterar Senha
          </h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Senha Atual
              </label>
              <input
                type="password"
                value={passwords.currentPassword}
                onChange={(e) =>
                  setPasswords({
                    ...passwords,
                    currentPassword: e.target.value,
                  })
                }
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nova Senha
              </label>
              <input
                type="password"
                value={passwords.newPassword}
                onChange={(e) =>
                  setPasswords({ ...passwords, newPassword: e.target.value })
                }
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Confirmar Nova Senha
              </label>
              <input
                type="password"
                value={passwords.confirmPassword}
                onChange={(e) =>
                  setPasswords({
                    ...passwords,
                    confirmPassword: e.target.value,
                  })
                }
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <button
                type="submit"
                disabled={loading}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50"
              >
                {loading ? "Alterando..." : "Alterar Senha"}
              </button>
            </div>
          </div>
        </form>

        {/* Seção de Exclusão de Conta */}
        <div className="pt-6 border-t">
          <button
            onClick={handleDeleteAccount}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
          >
            Deletar Conta
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
