import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCampaignReportByDate, getProjectById } from "../utils/api";
import dayjs from "dayjs";
import CampaignPerformanceTable from "../components/CampaignPerformanceTable";
import { FaArrowLeft } from "react-icons/fa";
import LoadingSpinner from "../components/LoadingSpinner";
import CpcCalculator from "../components/CpcCalculator";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const SubHeader = ({ projectId, campaignId, onBack }) => (
  <div className="bg-white border-b">
    <div className="px-4 py-3 flex items-center gap-3">
      <button onClick={onBack} className="text-gray-500 hover:text-gray-700">
        <FaArrowLeft size={16} />
      </button>
      <h2 className="text-base text-gray-700">Campanha #{campaignId}</h2>
    </div>
  </div>
);

const ProjectPerformance = () => {
  const { id: projectId, campaignId, adGroupId } = useParams();
  const [reportData, setReportData] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [totalGoogleAdsClicks, setTotalGoogleAdsClicks] = useState(0);
  const [totalConversions, setTotalConversions] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [totalGasto, setTotalGasto] = useState(0);
  const [totalLucro, setTotalLucro] = useState(0);

  const [roiStatus, setRoiStatus] = useState("");
  const [roiPercentage, setRoiPercentage] = useState(0);

  const [groupName, setGroupName] = useState("");
  const [utm, setUtm] = useState("");

  const [historicalData, setHistoricalData] = useState([]);

  const calculateRoiStatus = (roi, roiGoal) => {
    let status = "";
    if (roi >= roiGoal + 25) {
      status = `Acima da meta (+${(roi - roiGoal).toFixed(2)}% acima da meta)`;
    } else if (roi >= roiGoal) {
      status = `Dentro da meta (+${(roi - roiGoal).toFixed(2)}% acima da meta)`;
    } else if (roi >= roiGoal - 10) {
      status = `Perto da meta (-${(roiGoal - roi).toFixed(2)}% abaixo da meta)`;
    } else {
      status = `Muito abaixo da meta (-${(roiGoal - roi).toFixed(
        2
      )}% abaixo da meta)`;
    }
    setRoiStatus(status);
  };

  const fetchReportData = async (date) => {
    if (projectId && campaignId) {
      setIsLoading(true);
      try {
        const response = await getCampaignReportByDate(
          projectId,
          campaignId,
          adGroupId,
          date,
          date
        );

        if (response) {
          const reports = response || [];
          setReportData(reports);
          setError("");

          if (reports.length > 0) {
            const adGroupDetails = reports[0].adGroupDetails;
            setGroupName(adGroupDetails?.name || "Nome do Grupo Desconhecido");
            setUtm(adGroupDetails?.utm_campaign || "UTM Desconhecida");

            const latestReport = reports[reports.length - 1];
            const adGroup = latestReport.campaignDetails?.adGroups[0] || {};

            setTotalGoogleAdsClicks(adGroup.metrics?.clicks || 0);
            setTotalConversions(adGroup.metrics?.conversions || 0);

            const calculatedConversionRate = adGroup.metrics?.clicks
              ? (
                  (adGroup.metrics.conversions / adGroup.metrics.clicks) *
                  100
                ).toFixed(2)
              : 0;
            setConversionRate(calculatedConversionRate);

            setTotalGasto(
              ((adGroup.metrics?.cost_micros || 0) / 1000000).toFixed(2)
            );
            setTotalLucro((latestReport.roiRevenue || 0).toFixed(2));

            const currentRoi = latestReport.roiPercentage || 0;
            setRoiPercentage(currentRoi);

            const roiGoal = projectData?.config?.roiGoal || 50;
            calculateRoiStatus(currentRoi, roiGoal);
          }
        } else {
          setReportData([]);
          setError("Erro ao carregar o relatório.");
        }
      } catch (err) {
        console.error(err);
        setError("Erro ao carregar o relatório.");
        setReportData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchHistoricalData = async () => {
    const historicalData = [];
    for (let i = 1; i <= 7; i++) {
      const date = dayjs().subtract(i, "day").format("YYYY-MM-DD");
      const response = await getCampaignReportByDate(
        projectId,
        campaignId,
        adGroupId,
        date,
        date
      );
      if (response) {
        historicalData.push(...response);
      }
    }
    setHistoricalData(historicalData);
  };

  useEffect(() => {
    fetchReportData(selectedDate);
  }, [projectId, campaignId, selectedDate]);

  useEffect(() => {
    const fetchProjectData = async () => {
      if (projectId) {
        try {
          const data = await getProjectById(projectId);
          setProjectData(data);
        } catch (error) {
          console.error("Erro ao buscar projeto:", error);
        }
      }
    };

    fetchProjectData();
    fetchHistoricalData();
  }, [projectId]);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/project/${projectId}/performance`);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col min-h-screen w-full">
      <SubHeader
        projectId={projectId}
        campaignId={campaignId}
        onBack={handleBack}
      />

      <div className="flex-1 bg-gray-50 p-4">
        <div className="max-w-7xl mx-auto mb-6">
          <label
            htmlFor="date"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Selecione a Data
          </label>
          <input
            type="date"
            id="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {error ? (
          <div className="text-red-500 text-center p-4">{error}</div>
        ) : (
          <div className="max-w-7xl mx-auto space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-white p-4 rounded">
                <p className="text-sm text-gray-500">Total Gasto</p>
                <p className="text-2xl font-medium mt-1">R$ {totalGasto}</p>
              </div>

              <div className="bg-white p-4 rounded">
                <p className="text-sm text-gray-500">Lucro Total</p>
                <p className="text-2xl font-medium mt-1">R$ {totalLucro}</p>
              </div>

              <div
                className={`bg-white p-4 rounded border-l-4 ${
                  roiPercentage >= 80
                    ? "border-green-500"
                    : roiPercentage >= 50
                    ? "border-blue-500"
                    : roiPercentage >= 40
                    ? "border-yellow-500"
                    : "border-red-500"
                }`}
              >
                <p className="text-sm text-gray-500">ROI</p>
                <p className="text-2xl font-medium mt-1">
                  {roiPercentage.toFixed(2)}%
                </p>
                <p className="text-xs text-gray-500 mt-1">{roiStatus}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {[
                { label: "Cliques", value: totalGoogleAdsClicks },
                {
                  label: "Conversões",
                  value: `${totalConversions} (${conversionRate}%)`,
                },
              ].map((metric, index) => (
                <div key={index} className="bg-white p-4 rounded">
                  <p className="text-sm text-gray-500">{metric.label}</p>
                  <p className="text-lg font-medium mt-1">{metric.value}</p>
                </div>
              ))}
            </div>

            <div className="bg-white rounded overflow-hidden">
              <CampaignPerformanceTable
                reports={reportData}
                singleCampaign={true}
                config={projectData}
              />
            </div>

            <CpcCalculator
              historicalData={historicalData}
              roiGoal={projectData?.config?.roiGoal || 50}
              campaignId={campaignId}
              adGroupId={adGroupId}
              projectId={projectId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectPerformance;
