import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  getProjectById,
  getLastReport,
  addOrUpdateProjectConfig,
  getAggregatedReport,
} from "../utils/api";
import CampaignPerformanceTable from "../components/CampaignPerformanceTable";
import GamKeyMetric from "../components/GamKeyMetric";
import toast, { Toaster } from "react-hot-toast";
import {
  FaClock,
  FaArrowLeft,
  FaCog,
  FaInfoCircle,
  FaTimes,
} from "react-icons/fa";
import { Dialog } from "@headlessui/react";
import dayjs from "dayjs";

const SubHeader = ({ projectId }) => (
  <div className="bg-white border-b">
    <div className="max-w-7xl mx-auto px-6 py-4 flex items-center justify-between">
      <div className="flex items-center gap-4">
        <h2 className="text-lg font-medium text-gray-900">
          Performance do Projeto #{projectId}
        </h2>
      </div>
      <div className="flex gap-3">
        <button
          onClick={() => (window.location.href = `/project/${projectId}`)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <FaCog size={16} />
          Configurações do Projeto
        </button>
      </div>
    </div>
  </div>
);

const ProjectPerformance = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [reports, setReports] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reportRange, setReportRange] = useState("today");
  const [cpcBotEnabled, setCpcBotEnabled] = useState(false);
  const [projectPaused, setProjectPaused] = useState(false);
  const [additionalRoiValues, setAdditionalRoiValues] = useState({});
  const [isRevenueModalOpen, setIsRevenueModalOpen] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token não encontrado. Por favor, faça login.");
        }

        const decodedToken = jwtDecode(token);

        if (!decodedToken.scopes || !decodedToken.scopes.includes("projects")) {
          throw new Error("Você não tem permissão para acessar esta página.");
        }

        const accountId = decodedToken.id;
        const projectData = await getProjectById(id);

        if (!projectData) {
          throw new Error("Projeto não encontrado.");
        }

        const isOwner = projectData.accountId === accountId;
        const isManager = projectData.trafficManagersIds?.includes(accountId);

        if (!isOwner && !isManager) {
          throw new Error("Você não está autorizado a acessar este projeto.");
        }

        setIsAuthorized(true);
        setProjectDetails(projectData);

        setCpcBotEnabled(!Boolean(projectData.config?.cpcBotEnable ?? false));
        setProjectPaused(!Boolean(projectData.config?.runReports ?? false));

        const lastReportData = await getLastReport(id);
        setReports(lastReportData);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthorization();
  }, [id, navigate]);

  const handleReportRangeChange = async (range) => {
    setReportRange(range);
    setIsLoading(true);

    try {
      if (range !== "today") {
        const aggregatedReport = await getAggregatedReport(id, range);
        const aggregatedReportValidate = aggregatedReport.filter(
          (cp) => cp.campaignName
        );
        setReports(aggregatedReportValidate);
      } else {
        const lastReportData = await getLastReport(id);
        setReports(lastReportData);
      }
    } catch (error) {
      toast.error("Erro ao buscar relatório: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleCpcBot = async () => {
    const newCpcBotEnabled = !cpcBotEnabled;
    setCpcBotEnabled(newCpcBotEnabled || false);

    try {
      await addOrUpdateProjectConfig(
        id,
        "cpcBotEnable",
        Boolean(!newCpcBotEnabled)
      );
      toast.success("Configuração de Bot de CPC atualizada com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const toggleProjectPaused = async () => {
    const newProjectPaused = !projectPaused;
    setProjectPaused(newProjectPaused);

    try {
      await addOrUpdateProjectConfig(
        id,
        "runReports",
        Boolean(!newProjectPaused)
      );
      toast.success("Configuração de Pausa de Projeto atualizada com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const handleRoiValueChange = (presetId, value) => {
    setAdditionalRoiValues((prev) => ({
      ...prev,
      [presetId]: value,
    }));
  };

  const calculateMetrics = () => {
    let totalLucro = 0;
    let totalGasto = 0;
    let totalGanho = 0;
    let campanhasPositivas = 0;
    let campanhasNegativas = 0;
    let presetsBreakdown = [];
    let receitaCampanhas = 0;

    reports.forEach((report) => {
      totalGasto += report.googleAdsCost || 0;
      receitaCampanhas += report.adManagerRevenueBRL || 0;
      totalGanho += report.adManagerRevenueBRL || 0;

      if (report.roiPercentage > 0) {
        campanhasPositivas += 1;
      } else if (report.roiPercentage < 0) {
        campanhasNegativas += 1;
      }
    });

    const tax = projectDetails?.config?.Tax ?? 0;
    const revShare = projectDetails?.config?.RevShare ?? 0;
    const taxAmount = tax > 0 ? totalGanho * (tax / 100) : 0;
    const lucroInicial = totalGanho - totalGasto - taxAmount;

    Object.entries(additionalRoiValues).forEach(([presetId, value]) => {
      const storedPresets = localStorage.getItem(`tracking_presets_${id}`);
      if (storedPresets) {
        const presets = JSON.parse(storedPresets);
        const preset = presets.find((p) => p.id === presetId);
        if (preset) {
          const revShareAmount = (value * revShare) / 100;
          const valorAjustado = value - revShareAmount;

          if (preset.roiAction === "add") {
            totalGanho += valorAjustado;
            presetsBreakdown.push({
              name: preset.name,
              value: value,
              valorAjustado,
              action: "add",
            });
          } else if (preset.roiAction === "subtract") {
            totalGanho -= valorAjustado;
            presetsBreakdown.push({
              name: preset.name,
              value: value,
              valorAjustado,
              action: "subtract",
            });
          }
        }
      }
    });

    const taxAmountFinal = tax > 0 ? totalGanho * (tax / 100) : 0;
    totalLucro = totalGanho - totalGasto - taxAmountFinal;

    const mediaROI =
      totalGasto > 0 ? ((totalGanho - totalGasto) / totalGasto) * 100 : 0;

    return {
      totalLucro,
      lucroInicial,
      totalGasto,
      totalGanho,
      mediaROI,
      campanhasPositivas,
      campanhasNegativas,
      hasAdditionalRoi: Object.keys(additionalRoiValues).length > 0,
      presetsBreakdown,
      receitaCampanhas,
    };
  };

  const {
    totalLucro,
    lucroInicial,
    totalGasto,
    totalGanho,
    mediaROI,
    campanhasPositivas,
    campanhasNegativas,
    hasAdditionalRoi,
    presetsBreakdown,
    receitaCampanhas,
  } = calculateMetrics();

  const handleBack = () => {
    navigate(-1);
  };

  const getDateRangeFromPeriod = (period) => {
    const today = dayjs();
    const yesterday = today.subtract(1, "day");

    switch (period) {
      case "today":
        return {
          startDate: today.format("MM-DD-YYYY"),
          endDate: today.format("MM-DD-YYYY"),
        };
      case "yesterday":
        return {
          startDate: yesterday.format("MM-DD-YYYY"),
          endDate: yesterday.format("MM-DD-YYYY"),
        };
      case "last-7-days": {
        const startDate = yesterday.subtract(6, "days");
        return {
          startDate: startDate.format("MM-DD-YYYY"),
          endDate: yesterday.format("MM-DD-YYYY"),
        };
      }
      case "last-14-days": {
        const startDate = yesterday.subtract(13, "days");
        return {
          startDate: startDate.format("MM-DD-YYYY"),
          endDate: yesterday.format("MM-DD-YYYY"),
        };
      }
      case "last-30-days": {
        const startDate = yesterday.subtract(29, "days");
        return {
          startDate: startDate.format("MM-DD-YYYY"),
          endDate: yesterday.format("MM-DD-YYYY"),
        };
      }
      case "last-90-days": {
        const startDate = yesterday.subtract(89, "days");
        return {
          startDate: startDate.format("MM-DD-YYYY"),
          endDate: yesterday.format("MM-DD-YYYY"),
        };
      }
      default:
        return {
          startDate: today.format("MM-DD-YYYY"),
          endDate: today.format("MM-DD-YYYY"),
        };
    }
  };

  if (isLoading) {
    const loadingTexts = [
      "Calculando seu ROI enquanto você toma aquele cafézinho ☕",
      "Calculando métricas enquanto você sonha com aquele CPM alto 💭",
      "Analisando os dados enquanto você planeja sua próxima campanha 📊",
      "Processando números enquanto você respira fundo 🧘",
      "Organizando relatórios enquanto você estica as pernas 🚶",
      "Compilando informações enquanto você hidrata os olhos 👀",
      "Preparando insights enquanto você recarrega as energias 🔋",
      "Gerando estatísticas enquanto você faz uma pausa 💆",
      "Calculando resultados enquanto você se inspira 💡",
      "Processando performance enquanto você relaxa 🎯",
    ];

    return (
      <div className="flex flex-col flex-1">
        <SubHeader projectId={id} />
        <div className="flex-1 flex items-center justify-center">
          <div
            className="flex flex-col items-center transition-opacity duration-700"
            style={{ opacity: isLoading ? 1 : 0 }}
          >
            <div className="w-6 h-6 border-2 border-gray-200 border-t-blue-500 rounded-full animate-spin mb-4"></div>
            <span className="text-sm text-gray-600 font-medium mb-3">
              Processando dados
            </span>
            <span className="text-xs text-gray-500 animate-pulse text-center max-w-xs">
              {loadingTexts[Math.floor(Math.random() * loadingTexts.length)]}
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col flex-1">
        <SubHeader projectId={id} />
        <div className="flex-1 flex items-center justify-center">
          <p className="text-red-600">{error}</p>
        </div>
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-600">
          Você não está autorizado a acessar esta página.
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1">
      <SubHeader projectId={id} />
      <div className="flex-1 bg-gray-100 p-6">
        <Toaster position="top-right" reverseOrder={false} />

        {projectDetails && (
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Detalhes do Projeto
            </h2>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-xs font-semibold text-gray-500">
                  Tipo do Projeto
                </p>
                <p className="text-lg font-bold text-gray-800">
                  {projectDetails.projectType}
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-xs font-semibold text-gray-500">
                  Google Ads ID
                </p>
                <p className="text-lg font-bold text-gray-800">
                  {projectDetails.googleAdsId.join(", ")}
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-xs font-semibold text-gray-500">
                  AdManager ID
                </p>
                <p className="text-lg font-bold text-gray-800">
                  {projectDetails.admanagerId}
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-xs font-semibold text-gray-500">
                  GA4 Property ID
                </p>
                <p className="text-lg font-bold text-gray-800">
                  {projectDetails.ga4PropertyId}
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-xs font-semibold text-gray-500">Domain</p>
                <p className="text-lg font-bold text-gray-800">
                  {projectDetails.domain}
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-xs font-semibold text-gray-500">
                  Dollar Depreciation
                </p>
                <p className="text-lg font-bold text-gray-800">
                  {projectDetails.config?.DollarDepreciation || "N/A"}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="mb-8 text-center">
          <p className="text-sm text-gray-600">
            Selecione o período do relatório:
          </p>
          <div className="flex flex-wrap justify-center gap-2 mt-2">
            {[
              "today",
              "yesterday",
              "last-7-days",
              "last-14-days",
              "last-30-days",
              "last-90-days",
            ].map((range) => (
              <button
                key={range}
                className={`px-3 py-1 rounded-md border text-sm sm:text-base w-full sm:w-auto ${
                  reportRange === range
                    ? "bg-blue-500 text-white border-transparent"
                    : "bg-white text-gray-700 border-gray-300 hover:bg-gray-200"
                }`}
                onClick={() => handleReportRangeChange(range)}
              >
                {range === "today"
                  ? "Hoje"
                  : range === "yesterday"
                  ? "Ontem"
                  : range === "last-7-days"
                  ? "Últimos 7 dias"
                  : range === "last-14-days"
                  ? "Últimos 14 dias"
                  : range === "last-30-days"
                  ? "Últimos 30 dias"
                  : "Últimos 90 dias"}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-8 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-6">
          <div className="bg-blue-100 p-4 rounded-lg shadow">
            <div className="flex items-center justify-between">
              <p className="text-xs font-semibold text-blue-700">Lucro Total</p>
              <button
                onClick={() => setIsRevenueModalOpen(true)}
                className="text-blue-600 hover:text-blue-800"
                title="Ver detalhes da receita"
              >
                <FaInfoCircle size={16} />
              </button>
            </div>
            <p className="text-lg font-bold text-blue-900">
              R$ {totalLucro.toFixed(2)}
              {hasAdditionalRoi && (
                <>
                  <span className="text-xs text-blue-600 block">
                    (Inclui valores de presets)
                  </span>
                  <span className="text-xs text-blue-600 block">
                    (Anterior: R$ {lucroInicial.toFixed(2)})
                  </span>
                </>
              )}
            </p>
          </div>
          <div className="bg-green-100 p-4 rounded-lg shadow">
            <p className="text-xs font-semibold text-green-700">Investimento</p>
            <p className="text-lg font-bold text-green-900">
              R$ {totalGasto.toFixed(2)}
            </p>
          </div>
          <div className="bg-yellow-100 p-4 rounded-lg shadow">
            <p className="text-xs font-semibold text-yellow-700">Total Ganho</p>
            <p className="text-lg font-bold text-yellow-900">
              R$ {totalGanho.toFixed(2)}
            </p>
          </div>
          <div className="bg-indigo-100 p-4 rounded-lg shadow">
            <p className="text-xs font-semibold text-indigo-700">Média % ROI</p>
            <p className="text-lg font-bold text-indigo-900">
              {mediaROI.toFixed(2)}%
            </p>
          </div>
          <div className="bg-green-100 p-4 rounded-lg shadow">
            <p className="text-xs font-semibold text-green-700">
              Campanhas com ROI Positivo
            </p>
            <p className="text-lg font-bold text-green-900">
              {campanhasPositivas}
            </p>
          </div>
          <div className="bg-red-100 p-4 rounded-lg shadow">
            <p className="text-xs font-semibold text-red-700">
              Campanhas com ROI Negativo
            </p>
            <p className="text-lg font-bold text-red-900">
              {campanhasNegativas}
            </p>
          </div>
        </div>

        <div className="mb-8">
          {(() => {
            const isTrackingEnabled =
              localStorage.getItem(`tracking_enabled_${id}`) === "true";
            const notDisplayMessage = document.cookie.includes(
              "not_display_tracking_message=true"
            );

            if (!isTrackingEnabled && !notDisplayMessage) {
              const setIgnoreCookie = () => {
                document.cookie =
                  "not_display_tracking_message=true; path=/; max-age=31536000"; // 1 ano
                window.location.reload(); // Atualiza para esconder o aviso
              };

              return (
                <div className="p-4 bg-gray-50 rounded-lg shadow-sm border border-gray-200 text-sm">
                  <h3 className="font-medium text-gray-800 mb-2">
                    Ativar Rastreamento Adicional
                  </h3>
                  <p className="text-gray-600 mb-4">
                    Inclua receitas extras no ROI, como leads ou tráfego
                    orgânico, ativando o rastreamento detalhado.
                  </p>
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={setIgnoreCookie}
                      className="px-3 py-1 bg-gray-200 text-gray-600 rounded-lg hover:bg-gray-300 transition-colors"
                    >
                      Ignorar
                    </button>
                    <a
                      href={`/project/${id}`}
                      className="px-4 py-1 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                      Configurar
                    </a>
                  </div>
                </div>
              );
            }

            return (
              <>
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Métricas do Google Ad Manager
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {(() => {
                    const storedPresets = localStorage.getItem(
                      `tracking_presets_${id}`
                    );
                    if (!storedPresets) return null;

                    try {
                      const presets = JSON.parse(storedPresets);
                      return presets
                        .filter((preset) => preset.showInPerformance)
                        .map((preset) => (
                          <GamKeyMetric
                            key={preset.id}
                            projectId={id}
                            keyName={preset.key}
                            startDate={
                              getDateRangeFromPeriod(reportRange).startDate
                            }
                            endDate={
                              getDateRangeFromPeriod(reportRange).endDate
                            }
                            sourceFilter={preset.value}
                            config={projectDetails?.config}
                            roiAction={preset.roiAction}
                            onRoiValueChange={(value) =>
                              handleRoiValueChange(preset.id, value)
                            }
                          />
                        ));
                    } catch (error) {
                      console.error("Erro ao processar presets:", error);
                      return null;
                    }
                  })()}
                </div>
              </>
            );
          })()}
        </div>

        <div className="mb-8">
          <div className="flex flex-wrap items-center space-x-4">
            <div>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-blue-600"
                  checked={cpcBotEnabled}
                  onChange={toggleCpcBot}
                />
                <span className="ml-2 text-gray-700">Desligar Bot de CPC</span>
              </label>
              <p className="text-xs text-gray-500 mt-1">
                Desativa temporariamente os ajustes automáticos de CPC.
              </p>
            </div>
            <div>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-red-600"
                  checked={projectPaused}
                  onChange={toggleProjectPaused}
                />
                <span className="ml-2 text-gray-700">Pausar Monitoramento</span>
              </label>
              <p className="text-xs text-gray-500 mt-1">
                Pausa o acompanhamento de performance deste projeto.
              </p>
            </div>
          </div>
        </div>

        <CampaignPerformanceTable
          reports={reports}
          singleCampaign={false}
          config={projectDetails}
        />

        <p className="mt-4 text-xs text-gray-500 text-center">
          Os dados são armazenados em nosso servidor por apenas 90 dias. Após
          esse período, eles são permanentemente destruídos.
        </p>

        <Dialog
          open={isRevenueModalOpen}
          onClose={() => setIsRevenueModalOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="w-full max-w-2xl rounded-xl bg-white p-6 shadow-xl">
              <div className="relative">
                <Dialog.Title className="text-xl font-semibold text-gray-900 mb-6">
                  Detalhamento do Lucro Total
                </Dialog.Title>

                <div className="space-y-6">
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h3 className="text-sm font-medium text-gray-700 mb-2">
                      Campanhas
                    </h3>
                    <div className="space-y-2">
                      <div className="flex justify-between items-center">
                        <span>Receita Bruta:</span>
                        <div className="text-right">
                          <span className="font-medium text-green-600">
                            + R$ {receitaCampanhas.toFixed(2)}
                          </span>
                          <span className="block text-xs text-gray-500">
                            {((receitaCampanhas / totalGanho) * 100).toFixed(1)}
                            % do total
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <span>Investimento:</span>
                        <span className="font-medium text-red-600">
                          - R$ {totalGasto.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span>Tax ({projectDetails?.config?.Tax || 0}%):</span>
                        <span className="font-medium text-red-600">
                          - R${" "}
                          {(
                            (receitaCampanhas *
                              (projectDetails?.config?.Tax || 0)) /
                            100
                          ).toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between items-center pt-2 border-t">
                        <span>Lucro das Campanhas:</span>
                        <div className="text-right">
                          <span className="font-medium">
                            R${" "}
                            {(
                              receitaCampanhas -
                              totalGasto -
                              (receitaCampanhas *
                                (projectDetails?.config?.Tax || 0)) /
                                100
                            ).toFixed(2)}
                          </span>
                          <span className="block text-xs text-gray-500">
                            {(
                              ((receitaCampanhas -
                                totalGasto -
                                (receitaCampanhas *
                                  (projectDetails?.config?.Tax || 0)) /
                                  100) /
                                totalLucro) *
                              100
                            ).toFixed(1)}
                            % do lucro total
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {presetsBreakdown.length > 0 && (
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="text-sm font-medium text-gray-700 mb-2">
                        Presets
                      </h3>
                      <div className="space-y-3">
                        {presetsBreakdown.map((preset, index) => (
                          <div key={index} className="space-y-2">
                            <div className="flex justify-between items-center">
                              <span className="font-medium">{preset.name}</span>
                              <div className="text-right">
                                <span
                                  className={
                                    preset.action === "add"
                                      ? "text-green-600"
                                      : "text-red-600"
                                  }
                                >
                                  {preset.action === "add" ? "+" : "-"} R${" "}
                                  {preset.value.toFixed(2)}
                                </span>
                                <span className="block text-xs text-gray-500">
                                  {(
                                    (preset.valorAjustado / totalLucro) *
                                    100
                                  ).toFixed(1)}
                                  % do lucro total
                                </span>
                              </div>
                            </div>
                            <div className="text-sm text-gray-500 pl-4">
                              <div className="flex justify-between items-center">
                                <span>
                                  RevShare (
                                  {projectDetails?.config?.RevShare || 0}%):
                                </span>
                                <span className="text-red-600">
                                  - R${" "}
                                  {(
                                    (preset.value *
                                      (projectDetails?.config?.RevShare || 0)) /
                                    100
                                  ).toFixed(2)}
                                </span>
                              </div>
                              <div className="flex justify-between items-center pt-1 border-t">
                                <span>Valor após RevShare:</span>
                                <span
                                  className={
                                    preset.action === "add"
                                      ? "text-green-600"
                                      : "text-red-600"
                                  }
                                >
                                  {preset.action === "add" ? "+" : "-"} R${" "}
                                  {preset.valorAjustado.toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="bg-blue-50 p-4 rounded-lg">
                    <h3 className="text-sm font-medium text-blue-700 mb-2">
                      Resumo do Cálculo
                    </h3>
                    <div className="space-y-2">
                      <div className="flex justify-between items-center">
                        <span>Lucro das Campanhas:</span>
                        <div className="text-right">
                          <span className="font-medium">
                            R${" "}
                            {(
                              receitaCampanhas -
                              totalGasto -
                              (receitaCampanhas *
                                (projectDetails?.config?.Tax || 0)) /
                                100
                            ).toFixed(2)}
                          </span>
                          <span className="block text-xs text-gray-500">
                            {(
                              ((receitaCampanhas -
                                totalGasto -
                                (receitaCampanhas *
                                  (projectDetails?.config?.Tax || 0)) /
                                  100) /
                                totalLucro) *
                              100
                            ).toFixed(1)}
                            % do total
                          </span>
                        </div>
                      </div>
                      {presetsBreakdown.map((preset, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <span>Lucro do Preset ({preset.name}):</span>
                          <div className="text-right">
                            <span
                              className={
                                preset.action === "add"
                                  ? "text-green-600"
                                  : "text-red-600"
                              }
                            >
                              {preset.action === "add" ? "+" : "-"} R${" "}
                              {preset.valorAjustado.toFixed(2)}
                            </span>
                            <span className="block text-xs text-gray-500">
                              {(
                                (preset.valorAjustado / totalLucro) *
                                100
                              ).toFixed(1)}
                              % do total
                            </span>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-between items-center pt-2 border-t font-medium">
                        <span>Lucro Total:</span>
                        <span className="text-lg">
                          R$ {totalLucro.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => setIsRevenueModalOpen(false)}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                  <FaTimes size={20} />
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ProjectPerformance;
