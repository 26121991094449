import React, { useState } from "react";
import { FaWhatsapp, FaTimes } from "react-icons/fa";

const WhatsAppButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const phoneNumber = "5514991831220";

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedMessage = `*Assunto:* ${subject}%0A%0A${message}`;
    window.open(
      `https://wa.me/${phoneNumber}?text=${formattedMessage}`,
      "_blank"
    );
    setIsOpen(false);
    setSubject("");
    setMessage("");
  };

  return (
    <div className="fixed bottom-6 right-6 z-50">
      <div className="relative">
        <div
          className={`absolute bottom-16 right-0 transition-all duration-300 ${
            isOpen
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-4 pointer-events-none"
          }`}
        >
          <div className="bg-white rounded-xl shadow-lg w-72 overflow-hidden">
            <div className="bg-[#F0FDF4] p-4 flex justify-between items-center border-b border-gray-100">
              <h3 className="text-gray-700 font-medium">Enviar mensagem</h3>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-400 hover:text-gray-600 transition-colors"
              >
                <FaTimes />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="p-4">
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Assunto"
                  maxLength={30}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-100 focus:border-green-200 text-sm"
                  required
                />
                <div className="text-xs text-gray-400 mt-1">
                  {30 - subject.length} caracteres restantes
                </div>
              </div>
              <div className="mb-3">
                <textarea
                  placeholder="Sua mensagem"
                  maxLength={100}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-100 focus:border-green-200 resize-none text-sm"
                  rows={3}
                  required
                />
                <div className="text-xs text-gray-400 mt-1">
                  {100 - message.length} caracteres restantes
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-[#25D366] bg-opacity-10 text-[#25D366] py-2 px-4 rounded-lg hover:bg-opacity-20 transition-colors duration-200 text-sm font-medium"
              >
                Enviar mensagem
              </button>
            </form>
          </div>
        </div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-[#25D366] w-12 h-12 rounded-full flex items-center justify-center text-white text-xl shadow-lg hover:bg-[#20ba57] transition-colors duration-200"
        >
          <FaWhatsapp />
        </button>
      </div>
    </div>
  );
};

export default WhatsAppButton;
