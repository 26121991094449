import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaEnvelope,
  FaCoins,
  FaCalendarAlt,
  FaCrown,
  FaProjectDiagram,
  FaUsersCog,
  FaTools,
  FaSearch,
  FaTimes,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import {
  getUsers,
  getUserById,
  addUserScope,
  removeUserScope,
  deleteUser,
  banUser,
  addCredits,
  decrementCredits,
  getSubscriptionInfo,
} from "../utils/api";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import UserDetailsModal from "../components/UserDetailsModal";

// Componente para o cartão de estatísticas
const StatsCard = ({ title, value, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow">
    <div className="flex items-center gap-2 text-gray-500 mb-2">
      <Icon className="text-gray-400" size={16} />
      <p className="text-sm font-medium">{title}</p>
    </div>
    <p className="text-xl font-semibold text-gray-800">{value}</p>
  </div>
);

// Componente para exibir informações do plano
const PlanInfoCard = ({ planInfo }) => (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <div className="flex items-center gap-2 mb-4">
      <FaCrown className="text-yellow-500" size={20} />
      <h3 className="text-lg font-semibold text-gray-800">
        Informações do Plano
      </h3>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <p className="text-sm font-medium text-gray-600">Nome do Plano</p>
        <p className="text-base text-gray-800 capitalize">{planInfo.name}</p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-600">Preço por Crédito</p>
        <p className="text-base text-gray-800">R$ {planInfo.credits_price}</p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-600">
          Projetos Disponíveis
        </p>
        <p className="text-base text-gray-800">{planInfo.projects_available}</p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-600">Grupos de Anúncios</p>
        <p className="text-base text-gray-800">
          {planInfo.googleads.gads_groups_available}
        </p>
      </div>
    </div>

    {/* Recursos do Plano */}
    <div className="mt-4">
      <h4 className="text-sm font-medium text-gray-600 mb-2">
        Recursos Incluídos
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <Feature active={planInfo.roi_system} text="Sistema ROI" />
        <Feature
          active={planInfo.googleads.gads_integration}
          text="Integração Google Ads"
        />
        <Feature active={planInfo.automations.bot_cpc} text="Bot CPC" />
        <Feature active={planInfo.meta_integration} text="Integração Meta" />
        <Feature
          active={planInfo.pinterest_integration}
          text="Integração Pinterest"
        />
        <Feature active={planInfo.bing_integration} text="Integração Bing" />
      </div>
    </div>
  </div>
);

// Componente para exibir features do plano
const Feature = ({ active, text }) => (
  <div className="flex items-center gap-2">
    {active ? (
      <FaCheckCircle className="text-green-500" size={14} />
    ) : (
      <FaTimes className="text-red-500" size={14} />
    )}
    <span className="text-sm text-gray-600">{text}</span>
  </div>
);

// Componente principal
const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [scopes, setScopes] = useState("");
  const [credits, setCredits] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    bannedUsers: 0,
    totalCredits: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      if (!payload.scopes || !payload.scopes.includes("admin")) {
        navigate("/denied");
        return;
      }
    }

    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        if (response.success) {
          const sortedUsers = response.data.sort((a, b) => a.id - b.id);
          setUsers(sortedUsers);
          // Calcula estatísticas
          const activeUsers = sortedUsers.filter((user) => !isUserBanned(user));
          const bannedUsers = sortedUsers.filter((user) => isUserBanned(user));
          const totalCredits = sortedUsers.reduce(
            (sum, user) => sum + user.credits,
            0
          );

          setStats({
            totalUsers: sortedUsers.length,
            activeUsers: activeUsers.length,
            bannedUsers: bannedUsers.length,
            totalCredits,
          });
        }
      } catch (error) {
        console.error("Erro ao carregar usuários:", error);
        toast.error("Erro ao carregar usuários");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  const formatDate = (dateString) => {
    return dayjs(dateString).format("DD/MM/YYYY HH:mm");
  };

  const handleDeleteUser = (id) => {
    confirmAlert({
      title: "Confirmar exclusão",
      message: "Tem certeza que deseja deletar este usuário?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await deleteUser(id);
              setUsers(users.filter((user) => user.id !== id));
              toast.success("Usuário deletado com sucesso!");
            } catch (err) {
              toast.error("Erro ao deletar usuário.");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleViewUser = async (id) => {
    try {
      const [userResponse, subscriptionResponse] = await Promise.all([
        getUserById(id),
        getSubscriptionInfo(id),
      ]);

      if (userResponse.success) {
        // Combina as informações do usuário com a assinatura
        const userData = userResponse.data;
        if (subscriptionResponse.success && subscriptionResponse.data) {
          userData.subscription = subscriptionResponse.data;
        }
        setSelectedUser(userData);
        setModalVisible(true);
      } else {
        toast.error("Erro ao obter detalhes do usuário.");
      }
    } catch (err) {
      toast.error("Erro ao obter detalhes do usuário.");
    }
  };

  const handleAddScope = async (id) => {
    try {
      const response = await addUserScope(id, [scopes]);
      setSelectedUser(response.data);
      setScopes("");
      toast.success("Escopo adicionado com sucesso!");
    } catch (err) {
      toast.error("Erro ao adicionar escopo ao usuário.");
    }
  };

  const handleRemoveScope = async (id) => {
    try {
      const response = await removeUserScope(id, [scopes]);
      setSelectedUser(response.data);
      setScopes("");
      toast.success("Escopo removido com sucesso!");
    } catch (err) {
      toast.error("Erro ao remover escopo do usuário.");
    }
  };

  const handleBanUser = async (id) => {
    confirmAlert({
      title: "Confirmar banimento",
      message: "Tem certeza que deseja banir este usuário?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await banUser(id);
              setSelectedUser(null);
              setModalVisible(false);
              toast.success("Usuário banido com sucesso!");
            } catch (err) {
              toast.error("Erro ao banir usuário.");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleAddCredits = async (id, amount) => {
    try {
      const response = await addCredits(id, amount);
      setSelectedUser(response.data);
      toast.success("Créditos adicionados com sucesso!");
    } catch (err) {
      toast.error("Erro ao adicionar créditos ao usuário.");
    }
  };

  const handleDecrementCredits = async (id, amount) => {
    try {
      const response = await decrementCredits(id, amount);
      setSelectedUser(response.data);
      toast.success("Créditos decrementados com sucesso!");
    } catch (err) {
      toast.error("Erro ao decrementar créditos do usuário.");
    }
  };

  const isUserBanned = (user) => {
    return !user.scopes.includes("login");
  };

  const filteredUsers = users
    .sort((a, b) => a.id - b.id)
    .filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">
          Gerenciamento de Usuários
        </h1>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <StatsCard
          title="Total de Usuários"
          value={stats.totalUsers}
          icon={FaUser}
        />
        <StatsCard
          title="Usuários Ativos"
          value={stats.activeUsers}
          icon={FaCheckCircle}
        />
        <StatsCard
          title="Usuários Banidos"
          value={stats.bannedUsers}
          icon={FaExclamationCircle}
        />
        <StatsCard
          title="Total de Créditos"
          value={stats.totalCredits}
          icon={FaCoins}
        />
      </div>

      {/* Barra de Pesquisa */}
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <div className="relative">
          <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar por nome, email ou username..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Lista de Usuários */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Usuário
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Créditos
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                  Data de Registro
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell">
                  Último Acesso
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUsers.map((user) => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <span className="text-sm text-gray-900">{user.id}</span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-gray-900">
                        {user.name}
                      </span>
                      <span className="text-sm text-gray-500">
                        {user.email}
                      </span>
                      <span className="text-xs text-gray-400">
                        @{user.username}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-col">
                      <span className="text-sm text-gray-900">
                        {user.credits}
                      </span>
                      <span className="text-xs text-gray-500 capitalize">
                        {user.subscription_plan}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 hidden md:table-cell">
                    <span className="text-sm text-gray-500">
                      {formatDate(user.createdAt)}
                    </span>
                  </td>
                  <td className="px-6 py-4 hidden lg:table-cell">
                    <span className="text-sm text-gray-500">
                      {formatDate(user.updatedAt)}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        !isUserBanned(user)
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {!isUserBanned(user) ? "Ativo" : "Banido"}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <button
                      onClick={() => handleViewUser(user.id)}
                      className="text-blue-600 hover:text-blue-900 text-sm font-medium"
                    >
                      Detalhes
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal de Detalhes do Usuário */}
      <UserDetailsModal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        user={selectedUser}
        onAddScope={handleAddScope}
        onRemoveScope={handleRemoveScope}
        onAddCredits={handleAddCredits}
        onDecrementCredits={handleDecrementCredits}
        onBanUser={handleBanUser}
        onDeleteUser={handleDeleteUser}
        scopes={scopes}
        setScopes={setScopes}
        credits={credits}
        setCredits={setCredits}
        isUserBanned={isUserBanned}
      />

      <Toaster position="top-right" />
    </div>
  );
};

export default UsersPage;
