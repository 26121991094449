import React, { useState, useEffect } from "react";
import {
  generatePixPayment,
  getPixPaymentStatus,
  getUserInfo,
} from "../utils/api";
import { FaCopy, FaTimes, FaSpinner } from "react-icons/fa";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";

const GeneratePix = ({ isOpen, setIsOpen }) => {
  const [credits, setCredits] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [error, setError] = useState(null);
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [expirationTime, setExpirationTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(1800); // 30 minutos em segundos
  const [creditCost, setCreditCost] = useState(4.6); // Valor padrão
  const [planName, setPlanName] = useState("");

  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        const response = await getUserInfo();
        if (response.success) {
          const plan = response.data.plan;
          setCreditCost(plan.credits_price || 4.6);
          setPlanName(plan.name || "");
        }
      } catch (error) {
        console.error("Erro ao carregar informações do usuário:", error);
      }
    };

    loadUserInfo();
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    setCredits("");
    setTotalAmount(0);
    setQrCodeData(null);
    setError(null);
    setCheckingPayment(false);
    setExpirationTime(null);
    setTimeRemaining(1800); // Reset timer
  };

  const handleCreditsChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value > 0) {
      setCredits(value);
      setTotalAmount((value * creditCost).toFixed(2));
    }
  };

  const handleGeneratePix = async () => {
    if (credits <= 0) {
      setError("A quantidade de créditos deve ser maior que zero.");
      return;
    }

    setLoading(true);
    try {
      const description = `${credits} créditos para KaizenAds`;
      const response = await generatePixPayment(credits, description);

      if (response.success) {
        setQrCodeData(response.data);
        setError(null);
        setCheckingPayment(true);

        // Calcula o horário de expiração do pagamento
        const expiration = dayjs().add(30, "minute").format("HH:mm");
        setExpirationTime(expiration);
      } else {
        setError("Erro ao gerar o pagamento PIX.");
      }
    } catch (err) {
      setError("Erro ao gerar o pagamento PIX.");
    } finally {
      setLoading(false);
    }
  };

  const checkPaymentStatus = async () => {
    try {
      if (qrCodeData && qrCodeData.id) {
        const statusResponse = await getPixPaymentStatus(qrCodeData.id);
        if (
          statusResponse.success &&
          statusResponse.data.status === "approved"
        ) {
          setCheckingPayment(false);
          toast.success(
            "Pagamento confirmado! Créditos adicionados com sucesso."
          );

          setTimeout(() => {
            closeModal();
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }, 1000); // Fecha o modal após 1 segundo
        }
      }
    } catch (err) {
      console.error("Erro ao verificar status do pagamento:", err);
    }
  };

  useEffect(() => {
    let interval;
    if (checkingPayment) {
      interval = setInterval(checkPaymentStatus, 5000);
    }
    return () => clearInterval(interval);
  }, [checkingPayment, qrCodeData]);

  // Contagem regressiva do timer
  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeRemaining]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrCodeData.qr_code);
    toast("Código PIX copiado para a área de transferência!");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative bg-white p-4 sm:p-6 rounded-lg shadow-lg w-full max-w-md sm:w-96 mx-4 sm:mx-0">
        {/* Ícone de Fechar */}
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FaTimes />
        </button>

        <h2 className="text-lg font-bold mb-4">Pagamento PIX</h2>

        {!qrCodeData ? (
          <>
            <label className="block mb-2 text-sm text-gray-700">
              Quantidade de Créditos
            </label>
            <input
              type="number"
              value={credits}
              onChange={handleCreditsChange}
              className="w-full mb-2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Digite a quantidade de créditos"
            />
            <p className="text-xs text-gray-500 mb-4">
              Valor por crédito: R$ {creditCost.toFixed(2)}
            </p>
            {creditCost < 4.6 && (
              <p className="text-xs text-green-500 mb-4">
                Você está recebendo um desconto de R$
                {(4.6 - creditCost).toFixed(2)} por crédito devido ao seu plano{" "}
                {planName}.
              </p>
            )}
            {credits && (
              <p className="text-sm text-gray-700 mb-4">
                Valor total: <span className="font-bold">R$ {totalAmount}</span>
              </p>
            )}
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <button
              onClick={handleGeneratePix}
              disabled={loading || credits <= 0}
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors duration-200"
            >
              {loading ? "Gerando..." : "Gerar Pagamento PIX"}
            </button>
          </>
        ) : (
          <>
            <p className="text-sm text-gray-500 mb-2">
              Os créditos podem demorar até 5 minutos após o pagamento para
              serem creditados.
            </p>
            <p className="text-sm text-gray-700 mb-4">
              Escaneie o QR code abaixo para realizar o pagamento de{" "}
              <span className="font-bold">R$ {totalAmount}</span>.
            </p>
            <img
              src={`data:image/png;base64,${qrCodeData.qr_code_base64}`}
              alt="QR Code PIX"
              className="w-full h-auto mb-4"
            />
            <button
              onClick={copyToClipboard}
              className="w-full flex items-center justify-center bg-gray-100 text-black py-2 rounded hover:bg-gray-200 transition-colors duration-200 mb-4"
            >
              <FaCopy className="mr-2" />
              Copiar código PIX (copie e cole)
            </button>
            {/* Spinner minimalista e Timer */}
            <div className="flex items-center justify-center mb-4">
              <FaSpinner className="animate-spin text-gray-500 mr-2" />
              <p className="text-sm text-gray-500">
                Tempo restante: {formatTime(timeRemaining)}
              </p>
            </div>
            <p className="text-sm text-gray-500 mb-4">
              Você poderá realizar o pagamento até as{" "}
              <strong>{expirationTime}</strong>.
            </p>
            <p className="text-sm text-gray-500 mb-4">
              O crédito será adicionado automaticamente após a confirmação do
              pagamento.
            </p>
            <button
              onClick={closeModal}
              className="w-full bg-blue-100 text-blue-600 py-2 rounded hover:bg-blue-200 transition-colors duration-200"
            >
              Fechar
            </button>
          </>
        )}
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default GeneratePix;
