import React, { useState } from "react";
import { FaInfoCircle, FaExclamationTriangle } from "react-icons/fa";
import CancelSubscriptionButton from "./CancelSubscriptionButton";
import Spinner from "./Spinner";

const PlanChangeModal = ({
  isOpen,
  onClose,
  type,
  prorationDetails,
  onConfirm,
  isLoading,
}) => {
  if (!isOpen) return null;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatDate = (days) => {
    if (typeof days !== "number" || isNaN(days)) {
      return "Data inválida";
    }
    const date = new Date();
    date.setDate(date.getDate() + days);
    return new Intl.DateTimeFormat("pt-BR").format(date);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative mx-auto max-w-md rounded-lg bg-white p-6 shadow-xl">
        {type === "upgrade" ? (
          <>
            <div className="flex items-center text-xl font-semibold text-gray-900">
              <FaInfoCircle className="mr-2 text-blue-500" />
              Detalhes do Upgrade
            </div>

            <div className="mt-4 space-y-4">
              <p className="text-sm text-gray-600">
                Você está mudando do plano{" "}
                <span className="font-medium">
                  {prorationDetails.currentPlanName}
                </span>{" "}
                para o plano{" "}
                <span className="font-medium">
                  {prorationDetails.newPlanName}
                </span>
                .
              </p>

              <div className="rounded-lg bg-gray-50 p-4">
                <h4 className="font-medium text-gray-900">
                  Ajuste de cobrança
                </h4>
                <p className="mt-1 text-sm text-gray-600">
                  {prorationDetails.prorationAmount > 0
                    ? `Será cobrado ${formatCurrency(
                        prorationDetails.prorationAmount
                      )} agora`
                    : `Você receberá um crédito de ${formatCurrency(
                        Math.abs(prorationDetails.prorationAmount)
                      )}`}
                </p>
                <p className="mt-2 text-sm text-gray-600">
                  Próxima cobrança regular:{" "}
                  {formatCurrency(prorationDetails.newPlanDailyRate * 30)} em{" "}
                  {formatDate(prorationDetails.remainingDays)}
                </p>
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={onClose}
                disabled={isLoading}
                className="rounded-lg px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Cancelar
              </button>
              <button
                onClick={onConfirm}
                disabled={isLoading}
                className="rounded-lg bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <span className="flex items-center justify-center">
                    <Spinner className="mr-2" />
                    Processando...
                  </span>
                ) : (
                  "Confirmar Upgrade"
                )}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center text-xl font-semibold text-gray-900">
              <FaExclamationTriangle className="mr-2 text-orange-500" />
              Aviso sobre Downgrade
            </div>

            <div className="mt-4 space-y-4">
              <p className="text-sm text-gray-600">
                Não é possível fazer downgrade diretamente para um plano
                inferior.
              </p>

              <div className="rounded-lg bg-orange-50 p-4">
                <h4 className="font-medium text-gray-900">
                  Para mudar para um plano inferior:
                </h4>
                <ul className="mt-2 list-inside list-disc space-y-2 text-sm text-gray-600">
                  <li>Cancele sua assinatura atual</li>
                  <li>
                    Aguarde até o final do período atual (
                    {formatDate(prorationDetails?.remainingDays)})
                  </li>
                  <li>
                    Após esse período, você poderá assinar um novo plano de
                    menor valor
                  </li>
                </ul>
              </div>

              <p className="text-sm font-medium text-gray-700">
                Sua assinatura atual continuará ativa até o final do período já
                pago.
              </p>

              <div className="mt-4 border-t pt-4">
                <p className="mb-4 text-sm text-gray-600">
                  Se deseja prosseguir, você pode cancelar sua assinatura agora:
                </p>
                <CancelSubscriptionButton
                  subscriptionId={prorationDetails?.subscriptionId}
                  currentPlan={prorationDetails?.currentPlanName}
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={onClose}
                className="rounded-lg px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
              >
                Entendi
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PlanChangeModal;
