import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getProjects,
  generateTodayReport,
  updateProjectMcc,
  removeProjectMcc,
  updateGoogleAdsAccount,
} from "../utils/api";
import {
  FaProjectDiagram,
  FaExclamationTriangle,
  FaSync,
  FaChartLine,
  FaCog,
  FaGoogle,
  FaGlobe,
} from "react-icons/fa";
import { toast, Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import ReportGenerationModal from "../components/ReportGenerationModal";

const ProjectAdminDetails = () => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [generatingReport, setGeneratingReport] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [mccId, setMccId] = useState("");
  const [googleAdsId, setGoogleAdsId] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [reportError, setReportError] = useState(null);

  useEffect(() => {
    fetchProjectDetails();
  }, [id]);

  const fetchProjectDetails = async () => {
    try {
      const response = await getProjects();
      if (response.success) {
        const projectData = response.data.find((p) => p.id === Number(id));
        if (projectData) {
          setProject(projectData);
        } else {
          toast.error("Projeto não encontrado");
          navigate("/user-projects");
        }
      }
    } catch (error) {
      toast.error("Erro ao carregar detalhes do projeto");
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateReport = async () => {
    try {
      setGeneratingReport(true);
      setReportModalOpen(true);
      setReportError(null);

      const response = await generateTodayReport(project.id, "utm_campaign");

      if (response.success) {
        setReportData(response.data);
        toast.success("Relatório gerado com sucesso!");
      } else {
        setReportError({
          status: response.error?.status || 400,
          error: response.data || response.error?.message,
        });
      }
    } catch (error) {
      setReportError({
        status: error.response?.status || 500,
        error: error.response?.data || error.message,
      });
    } finally {
      setGeneratingReport(false);
    }
  };

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
    setReportData(null);
    setReportError(null);
  };

  const handleUpdateMcc = async () => {
    if (!mccId) {
      toast.error("Digite um MCC ID válido");
      return;
    }

    setIsUpdating(true);
    try {
      const response = await updateProjectMcc(project.id, mccId);
      if (response.success) {
        toast.success("MCC atualizado com sucesso!");
        fetchProjectDetails(); // Recarrega os dados do projeto
        setMccId(""); // Limpa o input
      } else {
        toast.error(response.error.message);
      }
    } catch (error) {
      toast.error("Erro ao atualizar MCC");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRemoveMcc = async () => {
    if (!project.googleAdsMccId) {
      toast.error("Projeto não possui MCC");
      return;
    }

    setIsUpdating(true);
    try {
      const response = await removeProjectMcc(project.id);
      if (response.success) {
        toast.success("MCC removido com sucesso!");
        fetchProjectDetails();
      } else {
        toast.error(response.error.message);
      }
    } catch (error) {
      toast.error("Erro ao remover MCC");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdateGoogleAds = async () => {
    if (!googleAdsId) {
      toast.error("Digite um ID do Google Ads válido");
      return;
    }

    setIsUpdating(true);
    try {
      const response = await updateGoogleAdsAccount(project.id, googleAdsId);
      if (response.success) {
        toast.success("Conta Google Ads atualizada com sucesso!");
        fetchProjectDetails();
        setGoogleAdsId("");
      } else {
        toast.error(response.error.message);
      }
    } catch (error) {
      toast.error("Erro ao atualizar conta Google Ads");
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!project) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      {/* Header */}
      <div className="flex justify-between items-start mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">
            {project.projectName}
          </h1>
          <p className="text-sm text-gray-500">{project.projectAbbreviation}</p>
        </div>
        <button
          onClick={handleGenerateReport}
          disabled={generatingReport}
          className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
            generatingReport
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          } text-white transition-colors`}
        >
          {generatingReport ? (
            <FaSync className="animate-spin" />
          ) : (
            <FaChartLine />
          )}
          {generatingReport ? "Gerando..." : "Gerar Relatório"}
        </button>
      </div>

      {/* Grid de Informações */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Informações Básicas */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-medium text-gray-800 mb-4">
            Informações Básicas
          </h2>
          <div className="space-y-3">
            <InfoItem
              icon={FaProjectDiagram}
              label="Tipo"
              value={project.projectType}
            />
            <InfoItem
              icon={FaGlobe}
              label="Domínio"
              value={project.domain || "Não definido"}
            />
            <InfoItem
              icon={FaGoogle}
              label="Google Ads ID"
              value={project.googleAdsId?.join(", ") || "Não definido"}
            />
            <InfoItem
              icon={FaCog}
              label="Configurações"
              value={
                <div className="flex gap-2">
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      project.config?.runReports
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {project.config?.runReports
                      ? "Relatórios Ativos"
                      : "Relatórios Inativos"}
                  </span>
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      project.config?.cpcBotEnable
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {project.config?.cpcBotEnable
                      ? "Bot CPC Ativo"
                      : "Bot CPC Inativo"}
                  </span>
                </div>
              }
            />
            <InfoItem
              icon={FaGoogle}
              label="MCC"
              value={
                <div className="flex items-center gap-2">
                  <span>{project.googleAdsMccId || "Não definido"}</span>
                  {project.googleAdsMccId && (
                    <button
                      onClick={handleRemoveMcc}
                      disabled={isUpdating}
                      className="text-red-500 hover:text-red-600 text-sm"
                    >
                      Remover
                    </button>
                  )}
                </div>
              }
            />
            <div className="mt-4 space-y-2">
              <input
                type="text"
                value={mccId}
                onChange={(e) => setMccId(e.target.value)}
                placeholder="Digite o MCC ID"
                className="w-full px-3 py-2 border rounded text-sm focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleUpdateMcc}
                disabled={isUpdating}
                className={`w-full px-4 py-2 rounded text-white text-sm ${
                  isUpdating
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
              >
                {isUpdating ? "Atualizando..." : "Atualizar MCC"}
              </button>
            </div>

            {/* Google Ads Account */}
            <div className="mt-6">
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Conta Google Ads
              </h3>
              <div className="space-y-2">
                <input
                  type="text"
                  value={googleAdsId}
                  onChange={(e) => setGoogleAdsId(e.target.value)}
                  placeholder="Digite o ID da conta Google Ads"
                  className="w-full px-3 py-2 border rounded text-sm focus:ring-2 focus:ring-blue-500"
                />
                <button
                  onClick={handleUpdateGoogleAds}
                  disabled={isUpdating}
                  className={`w-full px-4 py-2 rounded text-white text-sm ${
                    isUpdating
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-600"
                  }`}
                >
                  {isUpdating ? "Atualizando..." : "Atualizar Conta Google Ads"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Informações do Plano */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-medium text-gray-800 mb-4">
            Informações do Plano
          </h2>
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Plano Atual</span>
              <span className="text-sm font-medium capitalize">
                {project.planInfo?.name}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Preço</span>
              <span className="text-sm font-medium">
                R$ {project.planInfo?.price}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Grupos Disponíveis</span>
              <span className="text-sm font-medium">
                {project.planInfo?.googleads?.gads_groups_available}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Vencimento</span>
              <span className="text-sm font-medium">
                {dayjs(project.planInfo?.endDate).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <ReportGenerationModal
        isOpen={reportModalOpen}
        onClose={handleCloseReportModal}
        loading={generatingReport}
        data={reportData}
        error={reportError}
      />

      <Toaster position="top-right" />
    </div>
  );
};

const InfoItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-center gap-3">
    <div className="w-8 h-8 bg-gray-50 rounded-full flex items-center justify-center">
      <Icon className="text-gray-400" size={14} />
    </div>
    <div>
      <p className="text-sm text-gray-600">{label}</p>
      <div className="text-sm font-medium">{value}</div>
    </div>
  </div>
);

export default ProjectAdminDetails;
