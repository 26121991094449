import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const CountdownPromo = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = dayjs("2024-12-23 23:59:59");

    const calculateTimeLeft = () => {
      const now = dayjs();
      const diff = targetDate.diff(now, "second");

      if (diff <= 0) return null;

      const days = Math.floor(diff / (24 * 60 * 60));
      const hours = Math.floor((diff % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((diff % (60 * 60)) / 60);
      const seconds = diff % 60;

      return { days, hours, minutes, seconds };
    };

    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      if (timeLeft) {
        setTimeLeft(timeLeft);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const TimeUnit = ({ value, label }) => (
    <div className="text-center">
      <span className="text-2xl font-bold">
        {value.toString().padStart(2, "0")}
      </span>
      <p className="text-xs">{label}</p>
    </div>
  );

  const Separator = () => <div className="text-2xl font-bold">:</div>;

  return (
    <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-lg shadow-sm p-4 mb-6">
      <div className="text-center">
        <h3 className="text-lg font-medium mb-2">
          🎉 Período Especial de Lançamento
        </h3>
        <p className="text-sm mb-4">
          O Plano Free foi aumentado consideravelmente até 23/12/2024.
          Aproveite!
        </p>
        <div className="flex justify-center gap-4 mb-4">
          <TimeUnit value={timeLeft.days} label="dias" />
          <Separator />
          <TimeUnit value={timeLeft.hours} label="horas" />
          <Separator />
          <TimeUnit value={timeLeft.minutes} label="minutos" />
          <Separator />
          <TimeUnit value={timeLeft.seconds} label="segundos" />
        </div>
        <div className="space-y-2">
          <p className="text-sm">
            Após este período, o plano free terá limite de 10 grupos de ads e 1
            projeto, sem bot de CPC
          </p>
          <p className="text-sm font-medium">
            Garanta agora mesmo todos os seus grupos de anúncios ativos e
            economize!
          </p>
          <Link
            to="/pricing"
            className="inline-block mt-3 px-6 py-2 bg-white text-blue-600 rounded-lg font-medium hover:bg-blue-50 transition-colors"
          >
            Ver Tabela de Preços →
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CountdownPromo;
