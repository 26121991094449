import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import { getGamReportByKey } from "../utils/api";
import { Dialog } from "@headlessui/react";
import {
  FaExpand,
  FaTimes,
  FaChartLine,
  FaDatabase,
  FaSync,
} from "react-icons/fa";

const CACHE_DURATION = 60 * 60 * 1000; // 1 hora em milissegundos

const MetricCard = ({ label, value, unit = "", className = "" }) => (
  <div className={className}>
    <p className="text-sm text-gray-500">{label}</p>
    <p className="text-lg font-semibold">
      {unit && unit !== "%" ? unit : ""}
      {value}
      {unit === "%" ? "%" : ""}
    </p>
  </div>
);

const GamKeyMetric = ({
  projectId,
  keyName,
  startDate,
  endDate,
  sourceFilter,
  config,
  roiAction,
  onRoiValueChange,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dollarRate, setDollarRate] = useState(null);
  const [isFromCache, setIsFromCache] = useState(false);

  const getCacheKey = () =>
    `gam_metric_${projectId}_${keyName}_${startDate}_${endDate}_${sourceFilter}`;

  const fetchDollarRate = async () => {
    try {
      const response = await fetch(
        "https://economia.awesomeapi.com.br/json/last/USD-BRL"
      );
      const data = await response.json();
      return parseFloat(data.USDBRL.bid);
    } catch (error) {
      console.error("Erro ao buscar cotação do dólar:", error);
      return 5; // Valor fallback caso a API falhe
    }
  };

  const getFromCache = () => {
    const cached = localStorage.getItem(getCacheKey());
    if (!cached) return null;

    const { data: cachedData, timestamp } = JSON.parse(cached);
    const isExpired = dayjs().diff(dayjs(timestamp)) > CACHE_DURATION;

    if (isExpired) {
      localStorage.removeItem(getCacheKey());
      return null;
    }

    return cachedData;
  };

  const setCache = (data) => {
    const cacheData = {
      data,
      timestamp: dayjs().valueOf(),
    };
    localStorage.setItem(getCacheKey(), JSON.stringify(cacheData));
  };

  const processGamData = (rawData) => {
    if (!Array.isArray(rawData) || rawData.length === 0) return null;

    const filteredData = sourceFilter
      ? rawData.find((item) =>
          item.Dimension.CUSTOM_CRITERIA.includes(`utm_source=${sourceFilter}`)
        )
      : rawData[0];

    if (!filteredData) return null;

    const { Column, Dimension } = filteredData;

    return {
      revenue: parseFloat(Column.AD_EXCHANGE_LINE_ITEM_LEVEL_REVENUE || 0),
      impressions: parseInt(
        Column.AD_EXCHANGE_LINE_ITEM_LEVEL_IMPRESSIONS || 0
      ),
      clicks: parseInt(Column.AD_EXCHANGE_LINE_ITEM_LEVEL_CLICKS || 0),
      ctr: parseFloat(Column.AD_EXCHANGE_LINE_ITEM_LEVEL_CTR || 0),
      ecpm: parseFloat(Column.AD_EXCHANGE_LINE_ITEM_LEVEL_AVERAGE_ECPM || 0),
      responses: parseInt(Column.PROGRAMMATIC_RESPONSES_SERVED || 0),
      requests: parseInt(Column.TOTAL_PROGRAMMATIC_ELIGIBLE_AD_REQUESTS || 0),
      matchRate: parseFloat(Column.PROGRAMMATIC_MATCH_RATE || 0),
      source: Dimension.CUSTOM_CRITERIA.split("=")[1],
      domain: Dimension.DOMAIN,
      date: Dimension.DATE,
    };
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Busca taxa do dólar
      const rate = await fetchDollarRate();
      setDollarRate(rate);

      // Verifica cache primeiro
      const cachedData = getFromCache();
      if (cachedData) {
        setData(processGamData(cachedData));
        setLoading(false);
        setIsFromCache(true);
        return;
      }

      // Se não houver cache, faz a requisição
      const response = await getGamReportByKey(
        projectId,
        startDate,
        endDate,
        keyName
      );

      if (response.success) {
        const processedData = processGamData(response.data);
        if (processedData) {
          setData(processedData);
          setCache(response.data);
        } else {
          throw new Error("Dados não encontrados para este filtro");
        }
      } else {
        throw new Error(response.error || "Erro ao buscar dados");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [projectId, keyName, startDate, endDate, sourceFilter]);

  useEffect(() => {
    if (data && roiAction && onRoiValueChange) {
      const revenue = calculateRevenue(data.revenue);
      onRoiValueChange(revenue.originalBRL);
    }
  }, [data, roiAction, onRoiValueChange]);

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-4">
        <div className="animate-pulse space-y-2">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-6 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-3">
        <div className="text-sm text-red-500">{error}</div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-3">
        <div className="text-sm text-gray-500">Sem dados disponíveis</div>
      </div>
    );
  }

  const calculateRevenue = (revenueUSD) => {
    const tax = parseFloat(config?.Tax || 0);
    const revShare = parseFloat(config?.RevShare || 0);
    const dollarDepreciation = parseFloat(config?.DollarDepreciation || 0);

    // Aplicando descontos em USD
    const taxAmount = (revenueUSD * tax) / 100;
    const revShareAmount = (revenueUSD * revShare) / 100;
    const finalRevenueUSD = revenueUSD - taxAmount - revShareAmount;

    // Aplicando depreciação do dólar na cotação
    const adjustedDollarRate = (dollarRate || 5) - dollarDepreciation;

    return {
      originalUSD: revenueUSD,
      taxUSD: taxAmount,
      revShareUSD: revShareAmount,
      finalUSD: finalRevenueUSD,
      originalBRL: revenueUSD * (dollarRate || 5),
      finalBRL: finalRevenueUSD * adjustedDollarRate,
      adjustedDollarRate,
      discounts: {
        tax: `${tax}%`,
        revShare: `${revShare}%`,
        dollarDepreciation: `$${dollarDepreciation}`,
      },
    };
  };

  const revenue = calculateRevenue(data.revenue);

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow">
        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <FaChartLine className="text-blue-500" />
              <h4 className="text-sm font-medium text-gray-700">
                {sourceFilter || "Todas as fontes"}
              </h4>
              {isFromCache ? (
                <FaDatabase
                  className="text-gray-400 w-3 h-3"
                  title="Dados em cache"
                />
              ) : (
                <FaSync
                  className="text-green-500 w-3 h-3"
                  title="Dados atualizados"
                />
              )}
            </div>
            <div>
              <div className="text-lg font-bold text-gray-900">
                ${revenue.finalUSD.toFixed(2)}
                <span className="text-xs text-gray-500 ml-1">
                  (bruto: ${revenue.originalUSD.toFixed(2)})
                </span>
              </div>
              <div className="text-sm text-gray-500">
                R$ {revenue.finalBRL.toFixed(2)}
                <span className="text-xs ml-1">
                  (bruto: R$ {revenue.originalBRL.toFixed(2)})
                </span>
              </div>
            </div>
            <div className="flex gap-4 text-sm text-gray-600">
              <div>
                <span className="font-medium">
                  {data.impressions.toLocaleString()}
                </span>{" "}
                imp
              </div>
              <div>
                <span className="font-medium">
                  {(data.ctr * 100).toFixed(1)}%
                </span>{" "}
                CTR
              </div>
            </div>
          </div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="text-blue-600 hover:text-blue-800 p-1"
            title="Ver detalhes"
          >
            <FaExpand size={16} />
          </button>
        </div>
      </div>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-2xl rounded-xl bg-white p-6 shadow-xl">
            <div className="relative">
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
              >
                <FaTimes size={20} />
              </button>

              <Dialog.Title className="text-xl font-semibold text-gray-900 mb-6">
                Métricas Detalhadas - {sourceFilter || "Todas as fontes"}
              </Dialog.Title>

              <div className="grid grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h3 className="text-sm font-medium text-gray-700 mb-2">
                      Receita Detalhada (USD)
                    </h3>
                    <div className="space-y-1 text-sm">
                      <div className="flex justify-between">
                        <span>Bruto:</span>
                        <span className="font-medium">
                          ${revenue.originalUSD.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between text-red-500">
                        <span>Tax ({revenue.discounts.tax}):</span>
                        <span>-${revenue.taxUSD.toFixed(2)}</span>
                      </div>
                      <div className="flex justify-between text-red-500">
                        <span>RevShare ({revenue.discounts.revShare}):</span>
                        <span>-${revenue.revShareUSD.toFixed(2)}</span>
                      </div>
                      <div className="flex justify-between font-medium pt-1 border-t">
                        <span>Líquido:</span>
                        <span className="text-green-600">
                          ${revenue.finalUSD.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h3 className="text-sm font-medium text-gray-700 mb-2">
                      Receita Detalhada (BRL)
                    </h3>
                    <div className="space-y-1 text-sm">
                      <div className="flex justify-between">
                        <span>Dólar Original:</span>
                        <span>R$ {dollarRate?.toFixed(2)}</span>
                      </div>
                      <div className="flex justify-between text-red-500">
                        <span>Depreciação:</span>
                        <span>-{revenue.discounts.dollarDepreciation}</span>
                      </div>
                      <div className="flex justify-between font-medium pt-1 border-t">
                        <span>Dólar Final:</span>
                        <span>R$ {revenue.adjustedDollarRate.toFixed(2)}</span>
                      </div>
                      <div className="flex justify-between font-medium pt-1 border-t">
                        <span>Valor Final:</span>
                        <span className="text-green-600">
                          R$ {revenue.finalBRL.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <MetricCard
                    label="Impressões"
                    value={data.impressions.toLocaleString()}
                  />
                  <MetricCard
                    label="Cliques"
                    value={data.clicks.toLocaleString()}
                  />
                  <MetricCard
                    label="CTR"
                    value={(data.ctr * 100).toFixed(2)}
                    unit="%"
                  />
                  <MetricCard
                    label="eCPM"
                    value={data.ecpm.toFixed(2)}
                    unit="$"
                  />
                  <MetricCard
                    label="PMR"
                    value={(data.matchRate * 100).toFixed(2)}
                    unit="%"
                  />
                  <MetricCard
                    label="Requisições"
                    value={data.requests.toLocaleString()}
                  />
                </div>
              </div>

              <div className="mt-6 pt-4 border-t">
                <div className="text-sm text-gray-500">
                  <div>Domínio: {data.domain}</div>
                  <div>Data: {dayjs(data.date).format("DD/MM/YYYY")}</div>
                  <div className="flex items-center gap-1">
                    Última atualização: {dayjs().format("HH:mm:ss")}
                    {isFromCache ? (
                      <span className="flex items-center gap-1 text-gray-400">
                        <FaDatabase className="w-3 h-3" /> (cache)
                      </span>
                    ) : (
                      <span className="flex items-center gap-1 text-green-500">
                        <FaSync className="w-3 h-3" /> (atualizado)
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default GamKeyMetric;
