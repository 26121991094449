import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { FaPlus, FaTrash, FaSave } from "react-icons/fa";

const DEFAULT_KEYS = [
  "utm_term",
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "utm_content",
];

const ROI_ACTIONS = {
  ADD: "add",
  SUBTRACT: "subtract",
  DISPLAY: "display",
};

const PresetItem = ({ item, onSave, onDelete, isNew = false }) => {
  const [preset, setPreset] = useState(item);
  const [customKey, setCustomKey] = useState("");
  const [selectedKey, setSelectedKey] = useState(item.key || DEFAULT_KEYS[0]);

  const handleSave = () => {
    const finalPreset = {
      ...preset,
      key: selectedKey === "custom" ? customKey : selectedKey,
    };
    onSave(finalPreset, isNew);
  };

  const handleShowInPerformanceChange = (checked) => {
    const updatedPreset = { ...preset, showInPerformance: checked };
    setPreset(updatedPreset);
    onSave(updatedPreset, false);
  };

  return (
    <div className="flex items-center gap-2 p-2 bg-white rounded-lg shadow-sm">
      <input
        type="text"
        placeholder="Nome do Preset"
        className="flex-1 px-2 py-1 text-sm border rounded"
        value={preset.name}
        onChange={(e) => setPreset({ ...preset, name: e.target.value })}
      />

      <div className="flex-1">
        <select
          className="w-full px-2 py-1 text-sm border rounded"
          value={selectedKey}
          onChange={(e) => setSelectedKey(e.target.value)}
        >
          {DEFAULT_KEYS.map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
          <option value="custom">Personalizado</option>
        </select>
        {selectedKey === "custom" && (
          <input
            type="text"
            placeholder="Chave personalizada"
            className="w-full mt-1 px-2 py-1 text-sm border rounded"
            value={customKey}
            onChange={(e) => setCustomKey(e.target.value)}
          />
        )}
      </div>

      <input
        type="text"
        placeholder="Valor"
        className="flex-1 px-2 py-1 text-sm border rounded"
        value={preset.value}
        onChange={(e) => setPreset({ ...preset, value: e.target.value })}
      />

      <div className="flex items-center gap-2">
        <Switch
          checked={preset.showInPerformance}
          onChange={handleShowInPerformanceChange}
          className={`${
            preset.showInPerformance ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-5 w-10 items-center rounded-full`}
        >
          <span
            className={`${
              preset.showInPerformance ? "translate-x-5" : "translate-x-1"
            } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>

      <select
        className="px-2 py-1 text-sm border rounded"
        value={preset.roiAction}
        onChange={(e) => setPreset({ ...preset, roiAction: e.target.value })}
      >
        <option value={ROI_ACTIONS.ADD}>Somar ao ROI</option>
        <option value={ROI_ACTIONS.SUBTRACT}>Subtrair do ROI</option>
        <option value={ROI_ACTIONS.DISPLAY}>Apenas exibir</option>
      </select>

      <div className="flex gap-2">
        <button
          onClick={handleSave}
          className="p-1 text-blue-600 hover:text-blue-800"
        >
          <FaSave size={16} />
        </button>
        {!isNew && (
          <button
            onClick={() => onDelete(preset.id)}
            className="p-1 text-red-600 hover:text-red-800"
          >
            <FaTrash size={16} />
          </button>
        )}
      </div>
    </div>
  );
};

const DetailedTrackingPresets = ({ projectId }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [presets, setPresets] = useState([]);
  const [showNewPreset, setShowNewPreset] = useState(false);

  useEffect(() => {
    const storedState = localStorage.getItem(`tracking_enabled_${projectId}`);
    const storedPresets = localStorage.getItem(`tracking_presets_${projectId}`);

    if (storedState) {
      setIsEnabled(JSON.parse(storedState));
    }
    if (storedPresets) {
      setPresets(JSON.parse(storedPresets));
    }
  }, [projectId]);

  const handleToggle = (checked) => {
    setIsEnabled(checked);
    localStorage.setItem(
      `tracking_enabled_${projectId}`,
      JSON.stringify(checked)
    );
  };

  const handleSavePreset = (preset, isNew) => {
    let updatedPresets;
    if (isNew) {
      const newPreset = {
        ...preset,
        id: Date.now().toString(),
      };
      updatedPresets = [...presets, newPreset];
      setShowNewPreset(false);
    } else {
      updatedPresets = presets.map((p) => (p.id === preset.id ? preset : p));
    }
    setPresets(updatedPresets);
    localStorage.setItem(
      `tracking_presets_${projectId}`,
      JSON.stringify(updatedPresets)
    );
  };

  const handleDeletePreset = (presetId) => {
    const updatedPresets = presets.filter((p) => p.id !== presetId);
    setPresets(updatedPresets);
    localStorage.setItem(
      `tracking_presets_${projectId}`,
      JSON.stringify(updatedPresets)
    );
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-sm font-medium text-gray-900">
            Rastreamento Detalhado
          </h3>
          <p className="text-sm text-gray-500">
            Ative para habilitar o rastreamento detalhado e presets
            personalizados
          </p>
        </div>
        <Switch
          checked={isEnabled}
          onChange={handleToggle}
          className={`${
            isEnabled ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
        >
          <span
            className={`${
              isEnabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>

      {isEnabled && (
        <div className="mt-4 space-y-4">
          <div className="flex justify-between items-center">
            <h4 className="text-sm font-medium text-gray-700">
              Presets Cadastrados
            </h4>
            <button
              onClick={() => setShowNewPreset(true)}
              className="flex items-center gap-1 px-2 py-1 text-sm text-blue-600 hover:text-blue-800"
            >
              <FaPlus size={14} />
              Adicionar Preset
            </button>
          </div>

          <div className="space-y-2">
            {showNewPreset && (
              <PresetItem
                item={{
                  name: "",
                  key: DEFAULT_KEYS[0],
                  value: "",
                  showInPerformance: true,
                  roiAction: ROI_ACTIONS.DISPLAY,
                }}
                onSave={(preset) => handleSavePreset(preset, true)}
                onDelete={() => setShowNewPreset(false)}
                isNew={true}
              />
            )}

            {presets.map((preset) => (
              <PresetItem
                key={preset.id}
                item={preset}
                onSave={(updatedPreset) =>
                  handleSavePreset(updatedPreset, false)
                }
                onDelete={handleDeletePreset}
              />
            ))}

            {!showNewPreset && presets.length === 0 && (
              <p className="text-sm text-gray-500 text-center py-4">
                Nenhum preset cadastrado
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailedTrackingPresets;
