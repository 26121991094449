import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaEnvelope,
  FaCrown,
  FaCheckCircle,
  FaTimes,
  FaCoins,
  FaCalendarAlt,
  FaPlus,
  FaMinus,
  FaExternalLinkAlt,
} from "react-icons/fa";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import { createManualSubscription, getProjects } from "../utils/api";
import { useNavigate } from "react-router-dom";

const UserDetailsModal = ({
  isOpen,
  onClose,
  user,
  onAddScope,
  onRemoveScope,
  onAddCredits,
  onDecrementCredits,
  onBanUser,
  onDeleteUser,
  scopes,
  setScopes,
  credits,
  setCredits,
  isUserBanned,
}) => {
  const [newPlan, setNewPlan] = useState({
    planName: "",
    endDate: dayjs().add(1, "month").format("YYYY-MM-DD"),
  });
  const [userProjects, setUserProjects] = useState([]);
  const navigate = useNavigate();

  const formatDate = (date) => {
    if (!date) return "N/A";
    return dayjs(date).format("DD/MM/YYYY HH:mm");
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    if (user?.id) {
      fetchUserProjects();
    }
  }, [user?.id]);

  const fetchUserProjects = async () => {
    try {
      const response = await getProjects(user.id);
      if (response.success) {
        setUserProjects(response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar projetos:", error);
    }
  };

  const handleCreditsChange = (e) => {
    const value = e.target.value;
    // Permite apenas números positivos
    if (value === "" || (/^\d+$/.test(value) && parseInt(value) >= 0)) {
      setCredits(value);
    }
  };

  const handleAddCredits = () => {
    if (!credits || credits <= 0) {
      toast.error("Digite uma quantidade válida de créditos");
      return;
    }
    onAddCredits(user.id, parseInt(credits));
    setCredits(""); // Limpa o campo após a operação
  };

  const handleDecrementCredits = () => {
    if (!credits || credits <= 0) {
      toast.error("Digite uma quantidade válida de créditos");
      return;
    }
    if (parseInt(credits) > user.credits) {
      toast.error("Quantidade maior que os créditos disponíveis");
      return;
    }
    onDecrementCredits(user.id, parseInt(credits));
    setCredits(""); // Limpa o campo após a operação
  };

  const handleManualSubscription = async () => {
    if (!newPlan.planName) {
      toast.error("Selecione um plano");
      return;
    }

    try {
      const endDate = dayjs(newPlan.endDate).endOf("day").toISOString();
      const response = await createManualSubscription(
        user.id,
        newPlan.planName,
        endDate
      );

      if (response.success) {
        toast.success("Assinatura manual criada com sucesso");
        // Aqui você pode atualizar os dados do usuário se necessário
      } else {
        toast.error(response.error || "Erro ao criar assinatura");
      }
    } catch (error) {
      toast.error("Erro ao criar assinatura manual");
    }
  };

  if (!isOpen || !user) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity duration-300"
        onClick={onClose}
        style={{ opacity: isOpen ? 1 : 0 }}
      />

      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div
          className="bg-white rounded-lg shadow-lg w-full max-w-4xl relative transition-all duration-300"
          style={{
            transform: isOpen ? "scale(1)" : "scale(0.95)",
            opacity: isOpen ? 1 : 0,
          }}
        >
          {/* Header Simplificado */}
          <div className="flex items-center justify-between p-4 border-b">
            <div className="flex items-center gap-2">
              <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                <FaUser className="text-gray-600" />
              </div>
              <div>
                <h2 className="text-lg font-medium">
                  {user?.name || "Usuário"}
                </h2>
                <p className="text-sm text-gray-500">ID: {user?.id || "N/A"}</p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <FaTimes size={20} />
            </button>
          </div>

          {/* Conteúdo em Grid */}
          <div className="grid grid-cols-2 gap-6 p-6">
            {/* Coluna da Esquerda - Informações Básicas */}
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-4">
                Informações Básicas
              </h3>
              <div className="space-y-3">
                <InfoItem icon={FaEnvelope} value={user?.email || "N/A"} />
                <InfoItem icon={FaUser} value={`@${user?.username || "N/A"}`} />
                <InfoItem
                  icon={FaCoins}
                  value={`${user?.credits || 0} créditos`}
                />
                <InfoItem
                  icon={FaCalendarAlt}
                  value={formatDate(user?.createdAt)}
                  label="Registrado em"
                />
                <div className="flex justify-between items-center mt-4">
                  <span className="text-sm text-gray-500">
                    Total de Projetos: {userProjects.length || 0}
                  </span>
                  <button
                    onClick={() => navigate(`/user-projects/${user.id}`)}
                    className="text-blue-600 hover:text-blue-800 text-sm flex items-center gap-1"
                  >
                    Ver Projetos
                    <FaExternalLinkAlt size={12} />
                  </button>
                </div>
              </div>

              {/* Gerenciamento de Créditos */}
              <div className="mt-6">
                <h3 className="text-sm font-medium text-gray-500 mb-3">
                  Gerenciar Créditos
                </h3>
                <div className="flex gap-2">
                  <input
                    type="number"
                    value={credits}
                    onChange={handleCreditsChange}
                    min="0"
                    className="flex-1 px-3 py-2 border rounded text-sm focus:ring-2 focus:ring-blue-500"
                    placeholder="Quantidade"
                  />
                  <button
                    onClick={handleAddCredits}
                    disabled={!credits || credits <= 0}
                    className={`p-2 rounded text-white transition-colors ${
                      !credits || credits <= 0
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-green-500 hover:bg-green-600"
                    }`}
                    title={
                      !credits || credits <= 0
                        ? "Digite uma quantidade válida"
                        : "Adicionar créditos"
                    }
                  >
                    <FaPlus size={14} />
                  </button>
                  <button
                    onClick={handleDecrementCredits}
                    disabled={
                      !credits ||
                      credits <= 0 ||
                      parseInt(credits) > user.credits
                    }
                    className={`p-2 rounded text-white transition-colors ${
                      !credits ||
                      credits <= 0 ||
                      parseInt(credits) > user.credits
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-yellow-500 hover:bg-yellow-600"
                    }`}
                    title={
                      !credits
                        ? "Digite uma quantidade válida"
                        : parseInt(credits) > user.credits
                        ? "Quantidade maior que os créditos disponíveis"
                        : "Remover créditos"
                    }
                  >
                    <FaMinus size={14} />
                  </button>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  Créditos disponíveis: {user.credits}
                </p>
              </div>
            </div>

            {/* Coluna da Direita - Plano e Assinatura */}
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-4">
                Plano e Assinatura
              </h3>

              {/* Status da Assinatura */}
              <div className="bg-gray-50 rounded-lg p-4 mb-4">
                <div className="flex items-center justify-between mb-3">
                  <div>
                    <span className="text-sm font-medium capitalize">
                      {user?.subscription_plan || "Sem plano"}
                    </span>
                    <div className="text-xs text-gray-500 mt-1">
                      ID: {user?.subscription?.stripeSubscriptionId || "N/A"}
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    <StatusBadge
                      status={user?.subscription?.status || "inactive"}
                    />
                    <span className="text-xs text-gray-500 mt-1">
                      {user?.subscription?.lastPaymentStatus === "manual" ||
                      user?.subscription?.metadata?.manuallySet
                        ? "Licença Manual"
                        : "Via Stripe"}
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <p className="text-gray-500">Vencimento do Plano</p>
                    <p className="font-medium">
                      {formatDate(user?.subscription?.currentPeriodEnd)}
                    </p>
                  </div>
                  <div>
                    <p className="text-gray-500">Valor Mensal</p>
                    <p className="font-medium">
                      R$ {user?.subscription?.amount || 0}
                    </p>
                  </div>
                </div>

                {user?.subscription?.metadata && (
                  <div className="mt-2 text-xs text-gray-500">
                    {user.subscription.metadata.setByAdmin && (
                      <>
                        Definido por: Admin #
                        {user.subscription.metadata.setByAdmin}
                        <br />
                      </>
                    )}
                    {user.subscription.metadata.setAt && (
                      <>
                        Criado em:{" "}
                        {formatDate(user.subscription.metadata.setAt)}
                        {user.subscription.lastPaymentDate && (
                          <>
                            <br />
                            Último pagamento:{" "}
                            {formatDate(user.subscription.lastPaymentDate)}
                          </>
                        )}
                      </>
                    )}
                    {user.subscription.lastPaymentStatus && (
                      <div className="mt-1">
                        Status do Pagamento:{" "}
                        <span className="capitalize">
                          {user.subscription.lastPaymentStatus}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Recursos do Plano */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-gray-500 mb-2">
                  Recursos Incluídos
                </h4>
                <div className="grid grid-cols-1 gap-2">
                  <PlanFeature
                    active={user?.planInfo?.roi_system}
                    text="Sistema ROI"
                  />
                  <PlanFeature
                    active={user?.planInfo?.googleads?.gads_integration}
                    text="Google Ads"
                  />
                  <PlanFeature
                    active={user?.planInfo?.meta_integration}
                    text="Meta Ads"
                  />
                  <PlanFeature
                    active={user?.planInfo?.automations?.bot_cpc}
                    text="Bot CPC"
                  />
                </div>
              </div>

              {/* Coluna da Direita - após os Recursos do Plano */}
              <div className="mt-6 border-t pt-4">
                <div className="flex items-center justify-between mb-3">
                  <h4 className="text-sm font-medium text-gray-500">
                    Criar Assinatura Manual
                  </h4>
                </div>

                <div className="grid grid-cols-2 gap-3">
                  <select
                    value={newPlan.planName}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, planName: e.target.value })
                    }
                    className="px-3 py-2 border rounded text-sm focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Selecione um plano</option>
                    <option value="basic">Basic</option>
                    <option value="scale">Scale</option>
                    <option value="scale-plus">Scale Plus</option>
                    <option value="roi-master">ROI Master</option>
                  </select>

                  <input
                    type="date"
                    value={newPlan.endDate}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, endDate: e.target.value })
                    }
                    min={dayjs().format("YYYY-MM-DD")}
                    className="px-3 py-2 border rounded text-sm focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <button
                  onClick={handleManualSubscription}
                  className="mt-3 w-full px-4 py-2 bg-blue-500 text-white rounded text-sm font-medium hover:bg-blue-600 transition-colors"
                >
                  Criar Assinatura Manual
                </button>
              </div>
            </div>
          </div>

          {/* Footer com Ações */}
          <div className="border-t p-4">
            <div className="flex gap-3 justify-end">
              <button
                onClick={() => user?.id && onBanUser(user.id)}
                className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded hover:bg-red-600"
              >
                {isUserBanned(user) ? "Desbanir" : "Banir"}
              </button>
              <button
                onClick={() => user?.id && onDeleteUser(user.id)}
                className="px-4 py-2 text-sm font-medium text-white bg-gray-500 rounded hover:bg-gray-600"
              >
                Deletar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Componente para o badge de status
const StatusBadge = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "active":
        return "bg-green-100 text-green-800";
      case "inactive":
        return "bg-gray-100 text-gray-800";
      case "cancelled":
        return "bg-red-100 text-red-800";
      case "replaced":
        return "bg-yellow-100 text-yellow-800";
      case "pending":
        return "bg-blue-100 text-blue-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getStatusText = (status) => {
    switch (status?.toLowerCase()) {
      case "active":
        return "Ativa";
      case "inactive":
        return "Inativa";
      case "cancelled":
        return "Cancelada";
      case "replaced":
        return "Substituída";
      case "pending":
        return "Pendente";
      default:
        return status || "Desconhecido";
    }
  };

  return (
    <span
      className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(
        status
      )}`}
    >
      {getStatusText(status)}
    </span>
  );
};

// Componente InfoItem simplificado
const InfoItem = ({ icon: Icon, value, label }) => (
  <div className="flex items-center gap-3">
    <div className="w-8 h-8 bg-gray-50 rounded-full flex items-center justify-center">
      <Icon className="text-gray-400" size={14} />
    </div>
    <div>
      {label && <p className="text-xs text-gray-500">{label}</p>}
      <p className="text-sm">{value}</p>
    </div>
  </div>
);

// Componente PlanFeature simplificado
const PlanFeature = ({ active, text }) => (
  <div className="flex items-center gap-2">
    {active ? (
      <FaCheckCircle className="text-green-500" size={12} />
    ) : (
      <FaTimes className="text-gray-300" size={12} />
    )}
    <span className="text-sm text-gray-600">{text}</span>
  </div>
);

export default UserDetailsModal;
