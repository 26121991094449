// Navbar.jsx
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";

const Navbar = () => {
  return (
    <nav className="bg-white p-4 shadow-sm">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <div className="text-lg font-semibold text-gray-800">Dashboard</div>
          <Link
            to="/pricing"
            className="text-gray-600 hover:text-gray-800 transition-colors"
          >
            Preços
          </Link>
        </div>
        <Avatar />
      </div>
    </nav>
  );
};

export default Navbar;
