import React, { useState } from "react";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import { setCpcMin, setCpcMax, getCampaignReportByDate } from "../utils/api";
import LoadingSpinner from "./LoadingSpinner";

// Períodos fixos
const PERIOD_MAP = {
  "00:00-05:59": { label: "Madrugada", field: "dawn" },
  "06:00-11:59": { label: "Manhã", field: "morning" },
  "12:00-17:59": { label: "Tarde", field: "afternoon" },
  "18:00-23:59": { label: "Noite", field: "evening" },
};

/**
 * Retorna true se, entre duas entradas consecutivas do mesmo CPC,
 * passou-se +1 hora sem que googleAdsImpressions tenha aumentado.
 */
function hasOneHourFreeze(
  prevImpressions,
  currentImpressions,
  prevTime,
  currentTime
) {
  const gap = currentTime - prevTime; // ms
  // deltaImpressions = impressões atuais - impressões anteriores
  const deltaImpressions = currentImpressions - prevImpressions;
  if (deltaImpressions === 0 && gap > 3600000) {
    return true;
  }
  return false;
}

/**
 * Função que computa a pontuação (+1 / -1) para um CPC em um período.
 * - Para cada entrada do CPC, adicionamos ou subtraímos pontos
 *   conforme as regras definidas:
 *   1) Se ROI >= roiGoal => +1
 *   2) Se detectar "1h freeze" => -1
 *   (Você pode incluir outras regras, como cost > 0 => -1, etc.)
 */
function computeCpcScore(cpcEntries, roiGoal) {
  const sorted = [...cpcEntries].sort(
    (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
  );

  let score = 0;
  let prevTime = null;
  let prevImpressions = null;
  let hadFreeze = false; // Nova flag para rastrear se houve congelamento

  for (const entry of sorted) {
    // Regra 1: ROI >= roiGoal => +1
    if ((entry.roiPercentage || 0) >= roiGoal) {
      score += 1;
    }

    // Detectar freeze
    const currentTime = new Date(entry.dateTime).getTime();
    const currentImpressions = entry.googleAdsImpressions || 0;

    if (prevTime !== null && prevImpressions !== null) {
      if (
        hasOneHourFreeze(
          prevImpressions,
          currentImpressions,
          prevTime,
          currentTime
        )
      ) {
        hadFreeze = true; // Marca que houve congelamento
        score -= 2; // Aumenta a penalidade para -2
      }
    }

    prevTime = currentTime;
    prevImpressions = currentImpressions;
  }

  // Se teve freeze, retorna score muito baixo para descartar este CPC
  if (hadFreeze) {
    return -999;
  }

  return score;
}

function analyzeCpcPerformance(cpcEntries) {
  const sorted = [...cpcEntries].sort(
    (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
  );

  let totalFreezeTime = 0;
  let totalImpressions = 0;
  let totalCost = 0;
  let prevTime = null;
  let prevImpressions = null;

  for (const entry of sorted) {
    const currentTime = new Date(entry.dateTime).getTime();
    const currentImpressions = entry.googleAdsImpressions || 0;

    if (prevTime !== null && prevImpressions !== null) {
      if (
        hasOneHourFreeze(
          prevImpressions,
          currentImpressions,
          prevTime,
          currentTime
        )
      ) {
        totalFreezeTime += currentTime - prevTime;
      }
    }

    totalImpressions += currentImpressions;
    totalCost += (entry.cost_micros || 0) / 1000000;

    prevTime = currentTime;
    prevImpressions = currentImpressions;
  }

  const totalTime =
    sorted.length > 0
      ? new Date(sorted[sorted.length - 1].dateTime).getTime() -
        new Date(sorted[0].dateTime).getTime()
      : 0;

  return {
    freezePercentage: totalTime > 0 ? (totalFreezeTime / totalTime) * 100 : 0,
    totalImpressions,
    totalCost,
    averageHourlyImpressions:
      totalTime > 0 ? totalImpressions / (totalTime / 3600000) : 0,
  };
}

/**
 * Calcula CPC Min/Max por período, usando o "top half" de CPCs com maior pontuação.
 *
 * Passos:
 * 1) Agrupar entradas em 4 períodos.
 * 2) Para cada CPC no período, calcular "score" somando +1 / -1 conforme regras.
 * 3) Ordenar CPCs por score (desc).
 * 4) "top half" => slice(0, metade).
 * 5) cpcMin = menor CPC do top half
 *    cpcMax = maior CPC do top half com ROI >= roiGoal
 * 6) Se min > max e max != null => max = min
 */
function calculateCpcByPeriod(data, roiGoal) {
  // 4 buckets
  const periods = {
    "00:00-05:59": [],
    "06:00-11:59": [],
    "12:00-17:59": [],
    "18:00-23:59": [],
  };

  // Classifica cada entrada em um dos 4 buckets
  data.forEach((entry) => {
    const hour = new Date(entry.dateTime).getHours();
    let periodKey;
    if (hour < 6) periodKey = "00:00-05:59";
    else if (hour < 12) periodKey = "06:00-11:59";
    else if (hour < 18) periodKey = "12:00-17:59";
    else periodKey = "18:00-23:59";

    periods[periodKey].push(entry);
  });

  const results = {};
  for (const [period, entries] of Object.entries(periods)) {
    if (entries.length === 0) {
      results[period] = { min: null, max: null };
      continue;
    }

    // Agrupa por CPC
    const cpcMap = {};
    for (const e of entries) {
      const cpcVal = e.currentBid;
      if (!cpcMap[cpcVal]) cpcMap[cpcVal] = [];
      cpcMap[cpcVal].push(e);
    }

    let cpcScores = [];
    for (const [cpcValue, cpcEntries] of Object.entries(cpcMap)) {
      const score = computeCpcScore(cpcEntries, roiGoal);

      // Ignora apenas CPCs com freeze total (-999)
      if (score === -999) continue;

      const hasRoi = cpcEntries.some(
        (it) => (it.roiPercentage || 0) >= roiGoal
      );

      // Analisa performance do CPC
      const performance = analyzeCpcPerformance(cpcEntries);

      cpcScores.push({
        cpcValue: parseFloat(cpcValue),
        score,
        hasRoi,
        performance,
      });
    }

    // Ordenar primeiro por score
    cpcScores.sort((a, b) => b.score - a.score);

    if (cpcScores.length === 0) {
      results[period] = { min: null, max: null };
      continue;
    }

    // Para CPC Min, prioriza CPCs com:
    // 1. Menor tempo de freeze
    // 2. Maior média de impressões por hora
    // 3. Maior gasto total (indica consistência)
    const minCandidates = cpcScores
      .filter((x) => x.performance.freezePercentage < 50) // Menos de 50% do tempo congelado
      .sort((a, b) => {
        // Primeiro critério: tempo de freeze
        if (a.performance.freezePercentage !== b.performance.freezePercentage) {
          return (
            a.performance.freezePercentage - b.performance.freezePercentage
          );
        }
        // Segundo critério: média de impressões
        if (
          a.performance.averageHourlyImpressions !==
          b.performance.averageHourlyImpressions
        ) {
          return (
            b.performance.averageHourlyImpressions -
            a.performance.averageHourlyImpressions
          );
        }
        // Terceiro critério: gasto total
        return b.performance.totalCost - a.performance.totalCost;
      });

    // Para CPC Max, mantém apenas CPCs com ROI positivo
    const maxCandidates = cpcScores.filter((x) => x.score > 0 && x.hasRoi);

    // Define CPC Min como o menor valor entre os top 3 candidatos
    const cpcMinValue =
      minCandidates.length > 0
        ? Math.min(...minCandidates.slice(0, 3).map((x) => x.cpcValue))
        : null;

    // Define CPC Max como o maior valor com ROI positivo
    const cpcMaxValue =
      maxCandidates.length > 0
        ? Math.max(...maxCandidates.map((x) => x.cpcValue))
        : null;

    // Garante que min < max e que ambos sejam diferentes
    if (cpcMinValue != null && cpcMaxValue != null) {
      if (cpcMinValue === cpcMaxValue) {
        results[period] = {
          min: cpcMinValue * 0.9,
          max: cpcMaxValue,
        };
      } else {
        results[period] = {
          min: Math.min(cpcMinValue, cpcMaxValue),
          max: Math.max(cpcMinValue, cpcMaxValue),
        };
      }
    } else {
      results[period] = { min: cpcMinValue, max: cpcMaxValue };
    }
  }

  return results;
}

const CpcCalculator = ({ roiGoal, campaignId, adGroupId, projectId }) => {
  const [daysToAnalyze, setDaysToAnalyze] = useState(7);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [cpcResults, setCpcResults] = useState(null);
  const [globalMin, setGlobalMin] = useState(null);
  const [globalMax, setGlobalMax] = useState(null);

  /**
   * Lê dados dos últimos X dias.
   * Se algum dia não tiver dados => retorna null => erro
   */
  const fetchHistoricalData = async () => {
    const allData = [];
    let missingDays = 0;

    for (let i = 1; i <= daysToAnalyze; i++) {
      const date = dayjs().subtract(i, "day").format("YYYY-MM-DD");
      const response = await getCampaignReportByDate(
        projectId,
        campaignId,
        adGroupId,
        date,
        date
      );
      if (!response || response.length === 0) {
        missingDays++;
      } else {
        allData.push(...response);
      }
    }
    if (missingDays > 0) {
      return null;
    }
    return allData;
  };

  const handleCalculateCpc = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    setCpcResults(null);
    setGlobalMin(null);
    setGlobalMax(null);

    if (daysToAnalyze < 7) {
      toast("É recomendado analisar 7 dias para maior assertividade.", {
        icon: "⚠️",
      });
    }

    const historicalData = await fetchHistoricalData();
    if (!historicalData) {
      setErrorMessage("Não é possível fazer o cálculo. Alguns dias sem dados.");
      setIsLoading(false);
      return;
    }

    // Calcula CPC (score +1/-1) por período
    const periodResults = calculateCpcByPeriod(historicalData, roiGoal);
    setCpcResults(periodResults);

    // Para CPC global, repetimos e pegamos min e max final
    const pseudo = calculateCpcByPeriod(historicalData, roiGoal);

    let tmpMin = null;
    let tmpMax = null;
    Object.values(pseudo).forEach(({ min, max }) => {
      if (min != null) {
        if (tmpMin == null || min < tmpMin) {
          tmpMin = min;
        }
      }
      if (max != null) {
        if (tmpMax == null || max > tmpMax) {
          tmpMax = max;
        }
      }
    });

    if (tmpMin != null && tmpMax != null && tmpMin > tmpMax) {
      tmpMax = tmpMin;
    }

    setGlobalMin(tmpMin);
    setGlobalMax(tmpMax);

    setIsLoading(false);
  };

  /**
   * Aceita CPC min e max para UM per��odo
   */
  const handleAcceptCpc = async (cpcMin, cpcMax, period) => {
    try {
      if (cpcMin == null && cpcMax == null) return;
      const { field, label } = PERIOD_MAP[period];

      // Define Min
      if (cpcMin != null) {
        await setCpcMin(
          campaignId,
          adGroupId,
          {
            dawn: null,
            morning: null,
            afternoon: null,
            evening: null,
            [field]: cpcMin,
          },
          projectId
        );
      }
      // Define Max
      if (cpcMax != null) {
        await setCpcMax(
          campaignId,
          adGroupId,
          {
            dawn: null,
            morning: null,
            afternoon: null,
            evening: null,
            [field]: cpcMax,
          },
          projectId
        );
      }

      toast.success(`CPCs de "${label}" foram definidos com sucesso.`);
    } catch (error) {
      console.error("Erro ao definir CPCs:", error);
      toast.error("Ocorreu um erro ao definir CPCs.");
    }
  };

  /**
   * Aceita todas as sugestões em cada período
   */
  const handleAcceptAllSuggestions = async () => {
    if (!cpcResults) return;
    toast("Aplicando todas as sugestões de CPC...", { icon: "ℹ️" });

    const tasks = Object.entries(cpcResults).map(
      async ([period, { min, max }]) => {
        if (min != null || max != null) {
          await handleAcceptCpc(min, max, period);
        }
      }
    );

    try {
      await Promise.all(tasks);
      toast.success("Todas as sugestões de CPC foram aplicadas!");
    } catch (error) {
      console.error("Erro ao aplicar todas as sugestões:", error);
      toast.error("Houve um erro ao aplicar todas as sugestões.");
    }
  };

  /**
   * Aceita CPC global (Min/Max) para TODOS os períodos
   */
  const handleAcceptGlobalCpcs = async () => {
    toast(
      "Aplicando CPC Min/Max global para Madrugada, Manhã, Tarde e Noite...",
      {
        icon: "ℹ️",
      }
    );
    try {
      if (globalMin != null) {
        await setCpcMin(
          campaignId,
          adGroupId,
          {
            dawn: globalMin,
            morning: globalMin,
            afternoon: globalMin,
            evening: globalMin,
          },
          projectId
        );
      }
      if (globalMax != null) {
        await setCpcMax(
          campaignId,
          adGroupId,
          {
            dawn: globalMax,
            morning: globalMax,
            afternoon: globalMax,
            evening: globalMax,
          },
          projectId
        );
      }
      toast.success("CPC Global aplicado com sucesso!");
    } catch (error) {
      console.error("Erro ao definir CPC Global:", error);
      toast.error("Houve um erro ao definir CPC Global.");
    }
  };

  return (
    <div className="w-full py-6">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow px-4 py-6">
        {/* Seletor de dias */}
        <div className="mb-4">
          <label
            htmlFor="daysToAnalyze"
            className="block text-black font-semibold mb-1"
          >
            Quantidade de dias a analisar:
          </label>
          <select
            id="daysToAnalyze"
            value={daysToAnalyze}
            onChange={(e) => setDaysToAnalyze(parseInt(e.target.value, 10))}
            className="border border-[#D4D4D4] rounded p-2 w-full focus:outline-none"
          >
            {Array.from({ length: 7 }, (_, i) => i + 1).map((num) => (
              <option key={num} value={num}>
                {num} dia{num > 1 ? "s" : ""}
              </option>
            ))}
          </select>
        </div>

        {/* Botão Calcular */}
        <div className="border-b border-[#D4D4D4] pb-4 mb-4">
          <button
            onClick={handleCalculateCpc}
            className="bg-black text-white w-full py-2 rounded transition-colors hover:bg-[#808080]"
          >
            Calcular CPC (Score +1/-1)
          </button>
        </div>

        {/* Erro (se faltam dados) */}
        {errorMessage && (
          <div className="mb-4 text-red-600 font-semibold">{errorMessage}</div>
        )}

        {/* Explicação de como funciona */}
        <div className="mb-6">
          <h2 className="text-black font-semibold text-lg mb-2">
            Como o Algoritmo Funciona?
          </h2>
          <ul className="list-disc list-inside text-[#808080] space-y-1">
            <li>
              Para cada CPC, somamos pontos (+1) ou penalizamos (-1) em cada
              registro. Se ficar 1h sem subir impressões, -1; se ROI &ge;{" "}
              {roiGoal}, +1.
            </li>
            <li>
              Ordenamos por pontuação (desc), pegamos o top half e definimos CPC
              Min e Max.
            </li>
            <li>Se CPC Min &gt; CPC Max, forçamos Max = Min.</li>
            <li>Você pode aplicar estes CPCs por período ou globalmente.</li>
          </ul>
        </div>

        {/* Loading ou resultados */}
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          cpcResults && (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                {Object.entries(cpcResults).map(([period, { min, max }]) => {
                  const { label } = PERIOD_MAP[period];
                  const isDisabled = min == null && max == null;
                  return (
                    <div
                      key={period}
                      className="flex flex-col justify-between bg-[#F9F9F9] border border-[#E0E0E0] rounded p-4"
                    >
                      <div className="text-black font-semibold mb-2">
                        {label}
                      </div>
                      <div className="text-[#808080] text-sm">
                        <p>
                          CPC Min:{" "}
                          {min != null ? `R$ ${min.toFixed(2)}` : "N/A"}
                        </p>
                        <p>
                          CPC Max:{" "}
                          {max != null ? `R$ ${max.toFixed(2)}` : "N/A"}
                        </p>
                      </div>
                      <button
                        onClick={() => handleAcceptCpc(min, max, period)}
                        className={`mt-4 bg-black text-white py-2 rounded transition-colors hover:bg-[#808080] ${
                          isDisabled ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={isDisabled}
                      >
                        Aceitar Recomendações
                      </button>
                    </div>
                  );
                })}
              </div>

              {/* Aceitar tudo */}
              <div className="text-center mb-6">
                <button
                  onClick={handleAcceptAllSuggestions}
                  className="text-black font-semibold underline"
                >
                  Aceitar todas as sugestões
                </button>
              </div>

              {/* Aviso CPC Mínimos */}
              <p className="text-[#808080] text-sm mb-6 text-center">
                Aviso: Os CPCs Mínimos <strong>não</strong> são focados em
                permanecer no positivo, mas sim em{" "}
                <strong>manter a entrega</strong> das campanhas.
              </p>

              {/* CPC Global */}
              <div className="border-t border-[#D4D4D4] pt-4 mt-4 text-center">
                <h3 className="text-black font-semibold mb-2">
                  CPC Global (Score-based) para TODOS os períodos
                </h3>
                <div className="text-[#808080] text-sm mb-2">
                  <p>
                    CPC Min Global:{" "}
                    {globalMin != null ? `R$ ${globalMin.toFixed(2)}` : "N/A"}
                  </p>
                  <p>
                    CPC Max Global:{" "}
                    {globalMax != null ? `R$ ${globalMax.toFixed(2)}` : "N/A"}
                  </p>
                </div>
                <button
                  onClick={handleAcceptGlobalCpcs}
                  className={`bg-black text-white py-2 px-4 rounded transition-colors hover:bg-[#808080] ${
                    !globalMin && !globalMax
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={!globalMin && !globalMax}
                >
                  Aceitar CPC Global
                </button>
                <p className="text-[#808080] text-xs mt-2">
                  Ao clicar aqui, definimos o CPC Min/Max global para Madrugada,
                  Manhã, Tarde e Noite.
                </p>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default CpcCalculator;
