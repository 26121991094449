import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaCreditCard,
  FaHistory,
  FaDownload,
  FaExclamationCircle,
} from "react-icons/fa";
import { getBillingHistory } from "../utils/api";
import Spinner from "../components/Spinner";

const Billing = () => {
  const navigate = useNavigate();
  const [billingData, setBillingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBillingHistory = async () => {
      try {
        const response = await getBillingHistory();
        if (response.success) {
          setBillingData(response.billingHistory);
        } else {
          setError(response.error || "Erro ao carregar dados de faturamento");
        }
      } catch (error) {
        setError("Erro ao carregar dados de faturamento");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingHistory();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spinner className="w-8 h-8 text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-red-500">
        <FaExclamationCircle className="w-8 h-8 mb-2" />
        <p>{error}</p>
      </div>
    );
  }

  const currentSubscription = billingData?.subscriptions?.[0];
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("pt-BR");
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(amount);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Faturamento</h1>

      <div className="space-y-6">
        {/* Plano Atual */}
        {currentSubscription && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <FaCreditCard className="mr-2" />
              Plano Atual
            </h2>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-lg font-medium text-gray-900">
                    {currentSubscription.planName.toUpperCase()}
                  </p>
                  <p className="text-sm text-gray-600">
                    {formatCurrency(currentSubscription.amount)}/mês
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    Próxima cobrança em{" "}
                    {formatDate(currentSubscription.currentPeriodEnd)}
                  </p>
                </div>
                <button
                  onClick={() => navigate("/pricing")}
                  className="text-blue-500 hover:text-blue-600 text-sm font-medium"
                >
                  Alterar Plano
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Método de Pagamento */}
        {billingData?.customer?.default_payment_method && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <FaCreditCard className="mr-2" />
              Método de Pagamento
            </h2>
            <div className="flex items-center justify-between bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center">
                <FaCreditCard className="text-gray-400 mr-3" />
                <div>
                  <p className="font-medium">•••• •••• •••• 4242</p>
                  <p className="text-sm text-gray-600">Expira em 12/2024</p>
                </div>
              </div>
              <button className="text-blue-500 hover:text-blue-600 text-sm font-medium">
                Atualizar
              </button>
            </div>
          </div>
        )}

        {/* Histórico de Faturas */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
            <FaHistory className="mr-2" />
            Histórico de Faturas
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Data
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Valor
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Recibo
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {billingData?.invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {formatDate(invoice.created)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {formatCurrency(invoice.amount_paid)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          invoice.status === "paid"
                            ? "bg-green-100 text-green-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {invoice.status === "paid" ? "Pago" : "Pendente"}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {invoice.payment_intent?.receipt_url && (
                        <a
                          href={invoice.payment_intent.receipt_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:text-blue-600"
                        >
                          <FaDownload />
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Resumo */}
          {billingData?.summary && (
            <div className="mt-6 bg-gray-50 p-4 rounded-lg">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Resumo</h3>
              <div className="grid grid-cols-3 gap-4 text-sm">
                <div>
                  <p className="text-gray-500">Total Pago</p>
                  <p className="font-medium">
                    {formatCurrency(billingData.summary.total_paid)}
                  </p>
                </div>
                <div>
                  <p className="text-gray-500">Total Reembolsado</p>
                  <p className="font-medium">
                    {formatCurrency(billingData.summary.total_refunded)}
                  </p>
                </div>
                <div>
                  <p className="text-gray-500">Total Descontos</p>
                  <p className="font-medium">
                    {formatCurrency(billingData.summary.total_discounts)}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Billing;
