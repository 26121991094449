// src/pages/ProjectDetailsPage.js
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  FaGoogle,
  FaGlobe,
  FaChartLine,
  FaTrash,
  FaCog,
  FaUsers,
  FaArrowLeft,
} from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import IntegrateAccount from "../components/googleads/IntegrateAccount";
import AddToAdManager from "../components/admanager/AddToAdManager";
import AddDomain from "../components/AddDomain";
import TrafficManagers from "../components/TrafficManagers";
import ProjectConfigurations from "../components/ProjectConfigurations";
import DetailedTrackingPresets from "../components/DetailedTrackingPresets";
import * as api from "../utils/api";

// Componentes Base
const IntegrationCard = ({ icon: Icon, title, children, className = "" }) => {
  if (!Icon) return null; // Proteção contra Icon undefined

  return (
    <div className={`bg-white rounded-lg shadow-sm p-6 ${className}`}>
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 bg-gray-50 rounded-lg">
          <Icon className="text-gray-700" size={20} />
        </div>
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
      </div>
      {children}
    </div>
  );
};

const StatusBadge = ({ type, text }) => {
  const styles = {
    success: "bg-green-50 text-green-700 border-green-100",
    warning: "bg-yellow-50 text-yellow-700 border-yellow-100",
    error: "bg-red-50 text-red-700 border-red-100",
    info: "bg-blue-50 text-blue-700 border-blue-100",
  };

  return (
    <span
      className={`px-2.5 py-1 text-xs font-medium rounded-full border ${styles[type]}`}
    >
      {text}
    </span>
  );
};

// Novo componente SubHeader
const SubHeader = ({ projectId, onBack }) => (
  <div className="bg-white border-b mb-6">
    <div className="max-w-5xl mx-auto px-6 py-4 flex items-center justify-between">
      <div className="flex items-center gap-4">
        <button
          onClick={onBack}
          className="text-gray-600 hover:text-gray-900 transition-colors"
        >
          <FaArrowLeft size={16} />
        </button>
        <h2 className="text-lg font-medium text-gray-900">
          Projeto #{projectId}
        </h2>
      </div>
      <div className="flex gap-3">
        <button
          onClick={() =>
            (window.location.href = `/project/${projectId}/performance`)
          }
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <FaChartLine size={16} />
          Performance
        </button>
      </div>
    </div>
  </div>
);

// Componentes de Seção
const ProjectHeader = ({ project }) => (
  <IntegrationCard className="mb-6">
    <div>
      <h1 className="text-2xl font-bold text-gray-900">
        {project.projectName || project.projectType}
      </h1>
      <div className="flex gap-2 mt-2">
        <StatusBadge type="info" text={`ID: ${project.id}`} />
        {project.projectAbbreviation && (
          <StatusBadge
            type="info"
            text={`Sigla: ${project.projectAbbreviation}`}
          />
        )}
      </div>
    </div>
  </IntegrationCard>
);

const IntegrationsGrid = ({
  project,
  domain,
  setDomain,
  googleAdsAccounts,
  selectedAccount,
  handleIntegrateGoogleAds,
  handleResetGoogleAds,
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
    <IntegrationCard icon={FaGlobe} title="Domínio">
      <AddDomain
        projectId={project.id}
        existingDomain={domain}
        onDomainUpdated={setDomain}
      />
    </IntegrationCard>

    <IntegrationCard icon={FaGoogle} title="Google Ads">
      <div className="space-y-4">
        {googleAdsAccounts.length ? (
          <>
            <div className="flex items-center justify-between">
              <p className="text-gray-600">{googleAdsAccounts.join(", ")}</p>
              <button
                onClick={handleResetGoogleAds}
                className="text-gray-400 hover:text-red-500 transition-colors"
              >
                <FaTrash size={16} />
              </button>
            </div>
            {googleAdsAccounts.length > 1 && (
              <select
                value={selectedAccount}
                onChange={(e) => handleIntegrateGoogleAds(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg text-sm"
              >
                <option value="">Selecione uma conta</option>
                {googleAdsAccounts.map((account) => (
                  <option key={account} value={account}>
                    {account}
                  </option>
                ))}
              </select>
            )}
          </>
        ) : (
          <IntegrateAccount projectId={project.id} />
        )}
      </div>
    </IntegrationCard>

    <IntegrationCard icon={FaGoogle} title="Google Ad Manager">
      {project.admanagerId ? (
        <p className="text-gray-600">Conta: {project.admanagerId}</p>
      ) : (
        <AddToAdManager project={project} />
      )}
    </IntegrationCard>
  </div>
);

const ProjectDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [domain, setDomain] = useState("");
  const [googleAdsAccounts, setGoogleAdsAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [managers, setManagers] = useState([]);
  const [configurations, setConfigurations] = useState({
    RevShare: "",
    Tax: "",
    DollarDepreciation: "",
  });
  const [roiTargets, setRoiTargets] = useState({
    roiGoal: "",
    roiAbove: "",
    roiBelow: "",
  });
  const [accountId, setAccountId] = useState("");
  const hasFetched = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error(
            "Token não encontrado. Por favor, faça login novamente."
          );
        }

        const decodedToken = jwtDecode(token);
        const accountId = decodedToken.id;
        setAccountId(accountId);
        if (!accountId) {
          throw new Error("ID da conta não encontrado no token.");
        }

        const projectData = await api.getProjectById(id);
        if (!projectData) {
          throw new Error("Projeto não encontrado.");
        }

        setProject(projectData);

        const { config } = projectData;
        if (config) {
          setConfigurations({
            RevShare: config.RevShare || "",
            Tax: config.Tax || "",
            DollarDepreciation: config.DollarDepreciation || "",
          });
          setRoiTargets({
            roiGoal: config.roiGoal || "",
            roiAbove: config.roiAbove || "",
            roiBelow: config.roiBelow || "",
          });
        }

        if (projectData.googleAdsId) {
          setGoogleAdsAccounts(projectData.googleAdsId);
          if (projectData.googleAdsId.length === 1) {
            setSelectedAccount(projectData.googleAdsId[0]);
          }
        }

        const domainData = await api.getDomainInProject(id, accountId);
        setDomain(domainData || "");

        const managerData = await api.getManagerIdInProject(id, accountId);
        if (managerData && managerData.managers) {
          setManagers(managerData.managers);
        }
      } catch (error) {
        const errorMessage =
          typeof error === "string"
            ? error
            : error.message || "Erro desconhecido";
        setError(errorMessage);
        toast.error(errorMessage);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProject();
  }, [id, navigate]);

  // Função para integrar uma conta do Google Ads
  const handleIntegrateGoogleAds = async (account) => {
    try {
      const response = await api.updateGoogleAdsAccount(id, account);

      // Verifica se a resposta da API tem status 200
      if (response && response.googleAdsId) {
        setProject(response); // Atualiza o estado completo do projeto
        setGoogleAdsAccounts(response.googleAdsId);
        toast.success("Conta do Google Ads integrada com sucesso!");
      } else {
        throw new Error("Erro ao integrar conta do Google Ads.");
      }
    } catch (error) {
      toast.error("Erro ao integrar conta do Google Ads: " + error.message);
    }
  };

  // Função para resetar/desvincular todas as contas do Google Ads
  const handleResetGoogleAds = async () => {
    if (
      !window.confirm(
        "Tem certeza que deseja desvincular todas as contas do Google Ads deste projeto?"
      )
    ) {
      return;
    }

    try {
      const result = await api.resetGoogleAdsFields(id);

      // Mantém a lógica original que verifica o atributo 'success'
      if (result.success) {
        setGoogleAdsAccounts([]);
        setSelectedAccount("");
        toast.success("Contas do Google Ads foram resetadas com sucesso!");
      } else {
        throw new Error(
          result.error || "Erro ao resetar contas do Google Ads."
        );
      }
    } catch (error) {
      toast.error("Erro ao resetar contas do Google Ads: " + error.message);
    }
  };

  // Seção de Configurações
  const ConfigurationSection = () => {
    const isOwner = project.accountId === accountId;
    const isManager = project.trafficManagersIds?.includes(accountId);

    if (!isOwner && !isManager) return null;

    return (
      <div className="space-y-6">
        {isOwner && (
          <>
            <IntegrationCard icon={FaUsers} title="Gestores de Tráfego">
              <TrafficManagers
                projectId={project.id}
                managers={managers}
                setManagers={setManagers}
              />
            </IntegrationCard>

            <IntegrationCard icon={FaCog} title="Configurações do Projeto">
              <ProjectConfigurations
                projectId={project.id}
                initialConfigurations={configurations}
                initialRoiTargets={roiTargets}
              />
            </IntegrationCard>
          </>
        )}

        <IntegrationCard icon={FaCog} title="Presets e Filtros">
          <DetailedTrackingPresets projectId={project.id} />
        </IntegrationCard>
      </div>
    );
  };

  const handleBack = () => {
    navigate("/google-ads-api/projects");
  };

  if (isLoading) {
    return (
      <>
        <SubHeader projectId={id} onBack={handleBack} />
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-pulse text-gray-400">Carregando...</div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <SubHeader projectId={id} onBack={handleBack} />
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-red-500">{error}</div>
        </div>
      </>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <SubHeader projectId={id} onBack={handleBack} />
      <div className="p-6">
        <Toaster position="top-right" />
        <div className="max-w-5xl mx-auto space-y-6">
          <ProjectHeader project={project} />
          <IntegrationsGrid
            project={project}
            domain={domain}
            setDomain={setDomain}
            googleAdsAccounts={googleAdsAccounts}
            selectedAccount={selectedAccount}
            handleIntegrateGoogleAds={handleIntegrateGoogleAds}
            handleResetGoogleAds={handleResetGoogleAds}
          />
          <ConfigurationSection />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;
