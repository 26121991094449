import React, { useState, useEffect } from "react";
import {
  FaCheck,
  FaTimes,
  FaRobot,
  FaGoogle,
  FaMeta,
  FaPinterest,
  FaBing,
  FaWhatsapp,
  FaClock,
} from "react-icons/fa";
import WhatsAppButton from "../components/WhatsAppButton";
import {
  createStripeCheckoutSession,
  changeSubscriptionPlan,
  getCurrentSubscription,
  calculateProration,
} from "../utils/api";
import { useNavigate } from "react-router-dom";
import PlanChangeModal from "../components/PlanChangeModal";
import Spinner from "../components/Spinner";
import { jwtDecode } from "jwt-decode";

const CountdownButton = ({
  onSubscribe,
  isCurrentPlan,
  plan,
  isLoading,
  buttonText,
}) => {
  if (isCurrentPlan) {
    return (
      <div className="mt-8 w-full py-3 px-4 rounded-lg font-semibold bg-green-100 text-green-800 text-center">
        Plano Atual
      </div>
    );
  }

  return (
    <button
      onClick={() => onSubscribe(plan.toLowerCase())}
      disabled={isCurrentPlan || isLoading}
      className="mt-8 w-full py-3 px-4 rounded-lg font-semibold bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200"
    >
      {isLoading ? (
        <span className="flex items-center justify-center">
          <Spinner className="mr-2" />
          Processando...
        </span>
      ) : (
        buttonText
      )}
    </button>
  );
};

const PricingCard = ({
  plan,
  price,
  creditPrice,
  description,
  features,
  isPopular,
  currentPlan,
  onSubscribe,
  isLoading,
}) => {
  const isFreePlan = plan.toLowerCase() === "free";
  const isCurrentPlan = currentPlan === plan.toLowerCase();
  const currentPlanIndex = [
    "free",
    "basic",
    "scale",
    "scale-plus",
    "roi-master",
  ].indexOf(currentPlan?.toLowerCase());
  const thisPlanIndex = [
    "free",
    "basic",
    "scale",
    "scale-plus",
    "roi-master",
  ].indexOf(plan.toLowerCase());

  const getButtonText = () => {
    if (isCurrentPlan) return "Plano Atual";
    if (!currentPlan || currentPlan.toLowerCase() === "free")
      return "Fazer upgrade";
    if (thisPlanIndex > currentPlanIndex) return "Upgrade";
    return "Fazer Downgrade";
  };

  const showPopularBadge =
    isPopular && (!currentPlan || currentPlan.toLowerCase() === "free");

  return (
    <div
      className={`relative flex flex-col p-8 bg-white rounded-lg shadow-lg ${
        isCurrentPlan
          ? "border-2 border-green-500 ring-4 ring-green-200"
          : isPopular
          ? "border-2 border-blue-500"
          : "border border-gray-200"
      }`}
    >
      {showPopularBadge && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
          <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-semibold inline-block">
            Mais Popular
          </span>
        </div>
      )}
      {isCurrentPlan && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
          <span className="bg-green-500 text-white px-4 py-1 rounded-full text-sm font-semibold inline-block">
            Seu Plano Atual
          </span>
        </div>
      )}
      <h3 className="text-2xl font-bold text-gray-900">{plan}</h3>
      <div className="mt-4">
        <span className="text-4xl font-bold text-gray-900">
          {price === 0 ? "Grátis" : `R$ ${price}`}
        </span>
        {price > 0 && <span className="text-gray-600">/mês</span>}
      </div>
      <p className="mt-2 text-sm text-gray-500">Crédito: R$ {creditPrice}</p>
      <p className="mt-4 text-gray-600">{description}</p>
      <ul className="mt-6 space-y-4 flex-1">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center group relative">
            {feature.included ? (
              <FaCheck
                className={`h-5 w-5 mr-2 ${
                  feature.highlight ? "text-blue-500" : "text-green-500"
                }`}
              />
            ) : feature.comingSoon ? (
              <FaClock className="h-5 w-5 text-orange-400 mr-2" />
            ) : (
              <FaTimes className="h-5 w-5 text-red-500 mr-2" />
            )}
            <span
              className={`${
                feature.highlight
                  ? "text-blue-700 font-medium"
                  : "text-gray-600"
              } ${feature.comingSoon ? "flex items-center" : ""}`}
            >
              {feature.text}
              {feature.comingSoon && (
                <span className="ml-2 text-xs bg-orange-100 text-orange-600 px-2 py-0.5 rounded-full">
                  Em breve
                </span>
              )}
            </span>
          </li>
        ))}
      </ul>
      {!isFreePlan ? (
        <CountdownButton
          onSubscribe={onSubscribe}
          isCurrentPlan={isCurrentPlan}
          plan={plan}
          isLoading={isLoading}
          buttonText={getButtonText()}
        />
      ) : isCurrentPlan ? (
        <div className="mt-8 w-full py-3 px-4 rounded-lg font-semibold bg-green-100 text-green-800 text-center">
          Plano Atual
        </div>
      ) : null}
    </div>
  );
};

const Pricing = () => {
  const navigate = useNavigate();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    type: null,
    prorationDetails: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userScope, setUserScope] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserScope(decodedToken.scope);
    }

    const fetchCurrentPlan = async () => {
      try {
        const response = await getCurrentSubscription();
        if (response.success && response.subscription) {
          setCurrentPlan(response.subscription.planName);
          setSubscriptionDetails(response.subscription);
        } else {
          setCurrentPlan("free");
          setSubscriptionDetails(null);
        }
      } catch (error) {
        console.error("Erro ao buscar plano atual:", error);
        setCurrentPlan("free");
        setSubscriptionDetails(null);
      }
    };

    fetchCurrentPlan();
  }, []);

  const handleConfirmUpgrade = async () => {
    setIsLoading(true);
    try {
      const response = await changeSubscriptionPlan(
        modalData.prorationDetails.newPlanName
      );
      if (response.success) {
        alert("Plano atualizado com sucesso!");
        window.location.reload();
      } else {
        alert(response.error || "Erro ao atualizar o plano. Tente novamente.");
      }
    } catch (error) {
      console.error("Erro ao atualizar plano:", error);
      alert("Erro ao processar a atualização. Tente novamente.");
    } finally {
      setIsLoading(false);
      setModalData({ ...modalData, isOpen: false });
    }
  };

  const handlePlanChange = async (planName) => {
    setIsLoading(true);
    try {
      if (!currentPlan || currentPlan.toLowerCase() === "free") {
        try {
          const planMapping = {
            "scale plus": "scale-plus",
            "roi master": "roi-master",
          };
          const correctedPlanName =
            planMapping[planName.toLowerCase()] || planName.toLowerCase();

          const response = await createStripeCheckoutSession(correctedPlanName);
          if (response.success && response.url) {
            window.location.href = response.url;
          } else {
            alert("Erro ao processar a assinatura. Tente novamente.");
          }
          return;
        } catch (error) {
          console.error("Erro:", error);
          alert("Erro ao processar a solicitação. Tente novamente.");
          return;
        }
      }

      const currentPlanIndex = [
        "free",
        "basic",
        "scale",
        "scale-plus",
        "roi-master",
      ].indexOf(currentPlan?.toLowerCase());
      const newPlanIndex = [
        "free",
        "basic",
        "scale",
        "scale-plus",
        "roi-master",
      ].indexOf(planName.toLowerCase());

      if (newPlanIndex < currentPlanIndex) {
        setModalData({
          isOpen: true,
          type: "downgrade",
          prorationDetails: {
            remainingDays: 30,
            currentPlanName: currentPlan,
            subscriptionId: subscriptionDetails?.stripeSubscriptionId,
          },
        });
        return;
      }

      if (newPlanIndex > currentPlanIndex) {
        try {
          const planMapping = {
            "scale plus": "scale-plus",
            "roi master": "roi-master",
          };
          const correctedPlanName =
            planMapping[planName.toLowerCase()] || planName.toLowerCase();

          const response = await calculateProration(correctedPlanName);
          if (response.success) {
            setModalData({
              isOpen: true,
              type: "upgrade",
              prorationDetails: {
                ...response.prorationDetails,
                newPlanName: correctedPlanName,
                subscriptionId: subscriptionDetails?.stripeSubscriptionId,
              },
            });
          } else {
            alert("Erro ao calcular valores do upgrade. Tente novamente.");
          }
        } catch (error) {
          console.error("Erro:", error);
          alert("Erro ao processar a solicitação. Tente novamente.");
        }
      }
    } catch (error) {
      console.error("Erro:", error);
      alert("Erro ao processar a solicitação. Tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  const plans = [
    {
      plan: "Free",
      price: 0,
      creditPrice: "4,60",
      description:
        "Plano gratuito com limitações. Ideal para usuários iniciantes ou que desejam explorar a plataforma.",
      features: [
        { text: "Sistema de ROI", included: true },
        { text: "10 grupos Google Ads", included: true },
        { text: "1 Projeto disponível", included: true },
        { text: "1 Gerente disponível", included: true },
        { text: "Suporte via E-mail e WhatsApp", included: true },
        { text: "Bot CPC", included: false },
      ],
      isPopular: false,
    },
    {
      plan: "Basic",
      price: 72,
      creditPrice: "3,60",
      description:
        "Plano básico com mais recursos, ideal para usuários que buscam um custo-benefício intermediário.",
      features: [
        { text: "Todas as opções do plano Free", included: true },
        { text: "50 grupos Google Ads", included: true, highlight: true },
        { text: "10 Projetos disponíveis", included: true, highlight: true },
        { text: "20 Gerentes disponíveis", included: true, highlight: true },
        { text: "Bot CPC", included: true, highlight: true },
      ],
      isPopular: false,
    },
    {
      plan: "Scale",
      price: 144,
      creditPrice: "3,20",
      description:
        "Plano ideal para quem pretende escalar o negócio com mais recursos.",
      features: [
        { text: "Todas as opções do plano Basic", included: true },
        { text: "100 grupos Google Ads", included: true, highlight: true },
        { text: "20 Projetos disponíveis", included: true, highlight: true },
        { text: "40 Gerentes disponíveis", included: true, highlight: true },
        // { text: "Bot CPC Avançado", included: true, highlight: true },
      ],
      isPopular: true,
    },
    {
      plan: "Scale-Plus",
      price: 504,
      creditPrice: "3,00",
      description:
        "Plano empresarial com suporte para mais usuários e recursos avançados.",
      features: [
        { text: "Todas as opções do plano Scale", included: true },
        { text: "500 grupos Google Ads", included: true, highlight: true },
        { text: "100 Projetos disponíveis", included: true, highlight: true },
        { text: "200 Gerentes disponíveis", included: true, highlight: true },
        // { text: "Bot CPC Premium", included: true, highlight: true },
        { text: "Suporte prioritário", included: true, highlight: true },
      ],
      isPopular: false,
    },
    {
      plan: "ROI-Master",
      price: 1508,
      creditPrice: "2,80",
      description:
        "Plano empresarial completo, ideal para grandes equipes e empresas focadas em ROI máximo.",
      features: [
        { text: "Todas as opções do plano Scale Plus", included: true },
        { text: "1200 grupos Google Ads", included: true, highlight: true },
        { text: "300 Projetos disponíveis", included: true, highlight: true },
        { text: "600 Gerentes disponíveis", included: true, highlight: true },
        // { text: "Bot CPC Enterprise", included: true, highlight: true },
        {
          text: "Suporte com desenvolvedores",
          included: true,
          highlight: true,
        },
      ],
      isPopular: false,
    },
  ];

  const comingSoonFeatures = [
    {
      feature: "Integração Meta",
      availableFrom: "Basic",
      description: "Integração completa com Meta Ads",
    },
    {
      feature: "Integração Pinterest",
      availableFrom: "Scale",
      description: "Gerenciamento de campanhas no Pinterest",
    },
    {
      feature: "Integração Bing",
      availableFrom: "Scale",
      description: "Integração com Microsoft Advertising",
    },
    {
      feature: "Otimização de Campanhas",
      availableFrom: "Basic",
      description: "Sistema automatizado de otimização de campanhas",
    },
  ];

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 bg-gray-50 min-h-screen">
      <div className="max-w-[1440px] mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Planos e Preços
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Escolha o plano ideal para o seu negócio
          </p>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 xl:gap-6 2xl:gap-8">
          {plans.map((plan) => (
            <PricingCard
              key={plan.plan}
              {...plan}
              currentPlan={currentPlan}
              onSubscribe={handlePlanChange}
              isLoading={isLoading}
            />
          ))}
        </div>

        {/* Seção de Recursos em Desenvolvimento */}
        <div className="mt-16 bg-white rounded-lg shadow-lg p-8">
          <h3 className="text-2xl font-bold text-gray-900 text-center mb-8">
            Recursos em Desenvolvimento
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {comingSoonFeatures.map((feature, index) => (
              <div
                key={index}
                className="bg-gray-50 rounded-lg p-6 border border-gray-200"
              >
                <div className="flex items-center mb-4">
                  <FaClock className="h-5 w-5 text-orange-400 mr-2" />
                  <h4 className="font-semibold text-gray-900">
                    {feature.feature}
                  </h4>
                </div>
                <p className="text-gray-600 text-sm mb-3">
                  {feature.description}
                </p>
                <div className="flex items-center text-sm text-blue-600">
                  <span className="font-medium">
                    Disponível a partir do plano {feature.availableFrom}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-12 text-center space-y-4">
          <div className="flex items-center justify-center gap-8">
            <div className="flex items-center">
              <FaCheck className="h-5 w-5 text-green-500 mr-2" />
              <span className="text-sm text-gray-600">Disponível</span>
            </div>
            <div className="flex items-center">
              <FaTimes className="h-5 w-5 text-red-500 mr-2" />
              <span className="text-sm text-gray-600">Não disponível</span>
            </div>
          </div>
          <p className="text-sm text-gray-500">
            Todos os planos incluem suporte básico via E-mail e WhatsApp
          </p>
          <p className="text-sm text-gray-500">
            Para necessidades específicas, entre em contato com nossa equipe
            comercial
          </p>
        </div>
      </div>
      <PlanChangeModal
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ ...modalData, isOpen: false })}
        type={modalData.type}
        prorationDetails={modalData.prorationDetails}
        onConfirm={handleConfirmUpgrade}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Pricing;
