import React from "react";
import { FaSync, FaTimes, FaExclamationTriangle } from "react-icons/fa";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";

const ReportGenerationModal = ({ isOpen, onClose, loading, data, error }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity duration-300"
        onClick={loading ? undefined : onClose}
      />

      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl relative">
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b">
            <h2 className="text-lg font-medium text-gray-800">
              Relatório do Projeto
            </h2>
            {!loading && (
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-600"
              >
                <FaTimes size={20} />
              </button>
            )}
          </div>

          {/* Content */}
          <div className="p-4">
            {loading ? (
              <div className="flex flex-col items-center justify-center py-12">
                <FaSync className="animate-spin text-blue-500 text-4xl mb-4" />
                <p className="text-gray-600">
                  Gerando relatório... Isso pode levar alguns minutos.
                </p>
              </div>
            ) : error ? (
              <div className="flex flex-col items-center justify-center py-12 text-center">
                <FaExclamationTriangle className="text-red-500 text-4xl mb-4" />
                <div className="bg-red-50 p-4 rounded-lg max-w-lg w-full">
                  <div className="mb-2 text-sm font-medium text-red-800">
                    Status: {error.status}
                  </div>
                  <div className="overflow-auto">
                    <SyntaxHighlighter
                      language="json"
                      style={tomorrow}
                      customStyle={{
                        backgroundColor: "transparent",
                        fontSize: "14px",
                      }}
                    >
                      {JSON.stringify(error.error, null, 2)}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-gray-50 rounded-lg p-4 max-h-[60vh] overflow-auto">
                <SyntaxHighlighter
                  language="json"
                  style={tomorrow}
                  customStyle={{
                    backgroundColor: "transparent",
                    fontSize: "14px",
                  }}
                  showLineNumbers={true}
                  wrapLines={true}
                >
                  {JSON.stringify(data, null, 2)}
                </SyntaxHighlighter>
              </div>
            )}
          </div>

          {/* Footer */}
          {!loading && (
            <div className="border-t p-4 flex justify-end">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
              >
                Fechar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportGenerationModal;
